import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useConfigurationStore } from 'store';

const HomePage: React.FC = () => {
  const navigate = useNavigate();
  const { configurations } = useConfigurationStore();

  useEffect(() => {
    const {
      IsDeliveriesVisible,
      IsCustomerMasterVisible,
      IsWarehouseMasterVisible,
    } = configurations;

    if (IsDeliveriesVisible === true) {
      return navigate('/delivery', { replace: true });
    } else if (IsCustomerMasterVisible === true) {
      return navigate('/settings/customers', { replace: true });
    } else if (IsWarehouseMasterVisible === true) {
      return navigate('/settings/warehouses', { replace: true });
    } else if (
      IsCustomerMasterVisible === false &&
      IsWarehouseMasterVisible === false &&
      IsDeliveriesVisible === false 
    ) {
      return navigate('/profile', { replace: true });
    }
  }, [navigate, configurations]);

  return null;
};

export default HomePage;
