import {
  fetchOutboundList,
  fetchOutboundTypeTransactionDetail,
} from 'gateways/outbound';
import { ApiParams } from 'gateways/warehouse';
import { AggregatedOutboundData, Outbound } from 'models';
import { handleError } from 'views/shared/utils/error-utils';
import { removeEmptyValues } from 'views/shared/utils/string-utils';
import { create } from 'zustand';

type Action = {
  fetchOutboundsPaginated: (params?: ApiParams) => Promise<void>;
  fetchOutboundTransactionDetail: (
    fromDate: string,
    toDate: string
  ) => Promise<void>;
  resetOutboundData: () => void;
  setError?: (error: any) => void;
};

type State = {
  outbounds: Outbound[];
  hasNextPage: boolean;
  filter: ApiParams;
  fetching?: boolean;
  error?: any;
  aggregatedData?: AggregatedOutboundData;
};

const defaultAggregatedData: AggregatedOutboundData = {
  b2b: 0,
  b2c: 0,
  rtv: 0,
  transfer: 0,
};

const useOutboundStore = create<State & Action>()((set, get) => ({
  outbounds: [],
  hasNextPage: false,
  filter: {},
  error: null,
  fetching: false,
  selectedOutbound: null,
  aggregatedData: defaultAggregatedData,

  fetchOutboundsPaginated: async (params?: ApiParams): Promise<void> => {
    if (get().fetching) {
      return;
    }

    const apiParams = removeEmptyValues(params) ?? {};

    apiParams.page ??= 1;
    apiParams.limit ??= 10;

    set(() => ({
      fetching: true,
    }));

    try {
      const apiResponse = await fetchOutboundList(apiParams);

      set(state => ({
        filter: apiParams,
        outbounds:
          apiParams.page === 1
            ? apiResponse.collection
            : state.outbounds.concat(apiResponse.collection),
        hasNextPage:
          (apiParams.page as number) < apiResponse.metadata.last_page,
      }));
    } catch (err) {
      set(() => ({
        error: 'Failed to fetch Outbounds',
      }));
    }
    set(() => ({
      fetching: false,
    }));
  },

  fetchOutboundTransactionDetail: async (fromDate: string, toDate: string) => {
    try {
      const apiResponse = await fetchOutboundTypeTransactionDetail({
        fromDate,
        toDate,
      });
      const { data, failed } = handleError<AggregatedOutboundData>(apiResponse);

      if (failed) {
        throw new Error();
      }
      set(() => ({
        aggregatedData: {
          b2b: data.b2b ?? 0,
          b2c: data.b2c ?? 0,
          rtv: data.rtv ?? 0,
          transfer: data.transfer ?? 0,
        },
      }));
    } catch (err) {
      set(() => ({
        error: 'Failed to fetch outbound transaction detail.',
      }));
    }
  },

  resetOutboundData: () => {
    set(() => ({
      outbounds: [],
      hasNextPage: false,
      filter: {},
      error: null,
      fetching: false,
      selectedOutbound: null,
      aggregatedData: defaultAggregatedData,
    }));
  },

  setError: (error: any) => {
    set(() => ({
      error,
    }));
  },
}));

export { useOutboundStore };
