import { Button, StepWizardProps } from 'views/components/elements';
import { Box, List, ListItem, Typography } from '@mui/material';
// import { useNavigate } from 'react-router-dom';
import { PopUpForm } from '../delivery/StyledComponent';

interface ProductsNewProps extends StepWizardProps{
  count: number;
}

const ProductsNew: React.FC<ProductsNewProps> = (props) => {
  const { goToNamedStep, count } = props;

  if (count === 0) {
    goToNamedStep('summary-report');
    return null;
  }

  const handleNextStep = () => goToNamedStep('additional-info');
  const handleSkip = () => goToNamedStep('summary-report');

  return (
    <PopUpForm initialValues={undefined} onSubmit={handleNextStep}>
      {/* Order Details Section */}
      <Box style={{ display: 'flex', gap: '24px', marginBottom: '16px' }}>
        <span style={{ display: 'inline-flex', alignItems: 'center' }}>
          <Typography variant='subtitle1'>Total New Products - </Typography>
          <Box style={{ marginLeft: '10px' }}>{count}</Box>
        </span>
      </Box>
      <Box style={{ gap: '24px', marginBottom: '16px' }}>
        <span style={{ alignItems: 'center' }}>
          <Typography variant='caption'>
            We have identified some new products that are not fully registered
            in the system. Please provide the following information to complete
            the product entry:
          </Typography>
          <List sx={{ paddingLeft: 2, listStyleType: 'disc', pl: 2 }}>
            {[
              'UOM',
              'Product Group',
              'HSN Code',
              'Image URL',
              'Hazardous',
              'Fragile',
              'Liquids',
              'Heavy',
              'Perishable',
              'Min Life on Receipt',
              'Min Life on Despatch',
              'Supplier',
              'Long Description',
            ].map((item, index) => (
              <ListItem key={index} sx={{ display: 'list-item', padding: 0 }}>
                <Typography variant='caption' color='black'>
                  {item}
                </Typography>
              </ListItem>
            ))}
          </List>
        </span>
      </Box>
      <Typography variant='body2' sx={{ color: '#d9534f' }}>
        Note: Providing these details will ensure accurate inventory records and
        a smooth import process.
      </Typography>
      <Box mt={3} display='flex' justifyContent='right' gap={2}>
        <Button
          variant='contained'
          color='primary'
          size='small'
          onClick={handleNextStep}
          sx={{ textTransform: 'none' }}
        >
          Next
        </Button>
        <Button
          onClick={handleSkip}
          variant='text'
          size='small'
          color='secondary'
          className='cancel-btn'
          sx={{
            textTransform: 'none',
            color: '#323130',
            border: '1px solid #8A8886',
          }}
        >
          Skip
        </Button>
      </Box>
    </PopUpForm>
  );
};

export default ProductsNew;
