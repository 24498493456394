import { styled } from '@mui/material/styles';
import MaterialButton, { ButtonProps } from '@mui/material/Button';
import { mediaQuery } from 'theme/viewport';

const Button = styled(
  (props: ButtonProps & { to?: string; target?: string }) => (
    <MaterialButton
      disableRipple
      disableElevation
      variant={props.variant || 'contained'}
      {...props}
    />
  )
)({
  textTransform: 'none',
  fontSize: 12,
  fontWeight: 600,
  padding: '7.5px 16px',
  [mediaQuery.down('lg')]: {
    fontSize: 10,
    padding: '4px 8px',
    ['& .MuiSvgIcon-root']: {
      fontSize: 'inherit',
    },
  },
});

export default Button;
