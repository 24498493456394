import { fetchProductGroupList } from 'gateways/product';
import { ApiResponse, ProductGroup } from 'models';
import { ChangeEvent, SyntheticEvent, useState } from 'react';
import {
  SearchableDropDown,
  SearchableDropDownProps,
} from 'views/components/dropdown';
import { handleError } from 'views/shared/utils/error-utils';

interface ProductGroupSearchableDropDownProps {
  required?: boolean;
  onSelect: (ProductGroup: ProductGroup) => void;
  disabled?: boolean;
  value?: ProductGroup;
}

export function ProductGroupSearchableDropDown({
  required,
  onSelect,
  disabled,
  value,
}: ProductGroupSearchableDropDownProps) {
  const [options, setOptions] = useState<ProductGroup[]>([]);

  const handleSearch = async (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { value: query } = event.target;
    if (!query?.trim()) {
      return setOptions([]);
    }

    const apiResponse = await fetchProductGroupList({
      query,
      limit: 25,
    });

    const { failed, data } = handleError<ApiResponse<ProductGroup[]>>(apiResponse);

    setOptions(failed ? [] : [...data.collection]);
  };

  const onChange = (
    _: SyntheticEvent,
    newValue: ProductGroup | null,
    reason?: string
  ) => {
    if (reason === 'clear') {
      setOptions([]);
      onSelect(null);
      return;
    }

    if (newValue) {
      setOptions([newValue]);
      onSelect(newValue);
    }
  };

  const props: SearchableDropDownProps<ProductGroup> = {
    value,
    required: Boolean(required),
    handleSearch,
    onChange,
    options,
    label: 'ProductGroup',
    debounce: 400,
    isOptionEqualToValue: (option, value) =>
      option.business_id === value.business_id &&
      option.product_group_id === option.product_group_id,
    getOptionLabel: option => `${option.code} - ${option.name}`,
    onBlur: () => {
      setOptions(value ? [value] : []);
    },
    disabled,
  };

  return <SearchableDropDown {...props} />;
}
