import { Button, Input } from 'views/components/elements';
import {
  FormField,
  Required,
  Actions,
  Content,
  PopUpForm,
} from 'views/pages/delivery/StyledComponent';
import {
  removeExtraSpace,
  customerValidationSchema,
} from 'views/shared/utils/form-validator';
import { CustomerFormData } from 'models/Form';
import { Customer } from 'models';
import '../forms/index.scss';
import '../../delivery/forms/UserForm.scss';
import { ErrorMessage } from 'formik';
import { getCustomerById, updateCustomer } from 'gateways/customer';
import { handleError } from 'views/shared/utils/error-utils';
import { CustomerListResponse } from 'models/Customer';
import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import PopUpDialog from 'views/components/elements/PopUpDialog';
import WarningCard from 'views/components/elements/WarningCard';
import { useLocation, useNavigate } from 'react-router-dom';
import React from 'react';

interface CustomerFormProps {
  customerId: string;
  onSuccess?: () => void;
}

const CustomerEdit: React.FC<CustomerFormProps> = ({ customerId, onSuccess }) => {
  const [initialValues, setInitialValues] = useState<CustomerFormData | null>(null);
  const [isPopUpOpen, setIsPopUpOpen] = useState(false);
  const [isErrorPopUpOpen, setIsErrorPopUpOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const navigate = useNavigate();

  // Function to remove query params from the URL
  const clearParam = () => {
    queryParams.delete('customer_id');
    navigate(`/settings/customers?${queryParams.toString()}`);
  };

  useEffect(() => {
    const fetchCustomerData = async () => {
      try {
        const customerData = await getCustomerById({ customer_id: customerId });
        setInitialValues(() => customerData);
        setIsPopUpOpen(true);
      } catch (error) {
        setErrorMessage('Failed to fetch customer data');
        setIsErrorPopUpOpen(true);
      }
    };
  
    if (customerId) {
      fetchCustomerData();
    }
  }, [customerId]);

  const handleSubmit = async (customerData: CustomerFormData) => {
    const updatedCustomerData = {
      ...customerData,
      code: removeExtraSpace(customerData.code),
      name: removeExtraSpace(customerData.name),
      shipping_address: removeExtraSpace(customerData.shipping_address),
      phone: removeExtraSpace(customerData.phone),
      gst_number: removeExtraSpace(customerData.gst_number),
      lead_days: Number(customerData.lead_days),
    } as CustomerListResponse;

    try {
      const response = await updateCustomer(updatedCustomerData);
      const { failed, error } = handleError<Customer>(response);
      if (failed) {
        setErrorMessage(error || 'Failed to update customer');
        setIsErrorPopUpOpen(true);
      } else {
        onSuccess?.();
        clearParam();
        setIsPopUpOpen(false);
      }
    } catch (error) {
      setErrorMessage('An error occurred while updating the customer');
      setIsErrorPopUpOpen(true);
    }
  };

  const handleClosePopUp = () => {
    setIsPopUpOpen(false);
    clearParam();
  };

  if (!initialValues) return null;

  return (
    <>
      <PopUpDialog open={isPopUpOpen} onClose={handleClosePopUp} title="Edit Customer" sx={{ maxWidth: '880px', maxHeight: '452px' }}>
        <PopUpForm
          className='user-form form'
          initialValues={initialValues}
          validationSchema={customerValidationSchema}
          validateOnChange={true}
          validateOnBlur={true}
          onSubmit={handleSubmit}
          onReset={handleClosePopUp}
        >
          {({ errors, touched, setFieldTouched, setFieldValue }) => (
            <Content>
              <Box className="form-container" style={{ display: 'flex' }}>
                <Box style={{ width: '50%', paddingRight: '1rem' }}>
                  <FormField className='form-group' style={{ marginBottom: '18px' }}>
                    <label>Customer Code <Required>*</Required></label>
                    <Input
                      name='code'
                      placeholder='Customer Code'
                      disabled={true}
                      className={touched.code && errors.code ? 'error-input' : ''}
                      onChange={event => {
                        const { name, value } = event.target;
                        setFieldTouched(name, true);
                        setFieldValue(name, value);
                      }}
                    />
                    <ErrorMessage name='code' component='span' className='error' />
                  </FormField>
                  <FormField className='form-group' style={{ marginBottom: '18px' }}>
                    <label>
                      Phone <Required>*</Required>
                    </label>
                    <Input
                      name='phone'
                      placeholder='Phone'
                      className={touched.phone && errors.phone ? 'error-input' : ''}
                      onChange={event => {
                        const { name, value } = event.target;
                        setFieldTouched(name, true);
                        setFieldValue(name, value);
                      }}
                    />
                    <ErrorMessage name='phone' component='span' className='error' />
                  </FormField>
                  <FormField className='form-group' style={{ marginBottom: '18px' }}>
                    <label>Lead Days</label>
                    <Input
                      name='lead_days'
                      placeholder='Lead Days'
                      className={
                        touched.lead_days && errors.lead_days ? 'error-input' : ''
                      }
                      onChange={event => {
                        const { name, value } = event.target;
                        setFieldTouched(name, true);
                        setFieldValue(name, value);
                      }}
                    />
                    <ErrorMessage name='lead_days' component='span' className='error' />
                  </FormField>
                </Box>
                <Box style={{ width: '50%', paddingLeft: '1rem' }}>
                  <FormField className='form-group' style={{ marginBottom: '18px' }}>
                    <label>
                      Customer Name <Required>*</Required>
                    </label>
                    <Input
                      name='name'
                      placeholder='Customer Name'
                      className={touched.name && errors.name ? 'error-input' : ''}
                      onChange={event => {
                        const { name, value } = event.target;
                        setFieldTouched(name, true);
                        setFieldValue(name, value);
                      }}
                    />
                    <ErrorMessage name='name' component='span' className='error' />
                  </FormField>
                  <FormField className='form-group' style={{ marginBottom: '18px' }}>
                    <label>
                      GST Number 
                    </label>
                    <Input
                      name='gst_number'
                      placeholder='GST Number'
                      className={
                        touched.gst_number && errors.gst_number ? 'error-input' : ''
                      }
                      onChange={event => {
                        const { name, value } = event.target;
                        setFieldTouched(name, true);
                        setFieldValue(name, value);
                      }}
                    />
                    <ErrorMessage
                      name='gst_number'
                      component='span'
                      className='error'
                    />
                  </FormField>
                  <FormField className='form-group' style={{ marginBottom: '18px' }}>
                    <label>
                      Shipping Location <Required>*</Required>
                    </label>
                    <Input
                      name='shipping_address'
                      placeholder='Shipping Location'
                      className={
                        touched.shipping_address && errors.shipping_address
                          ? 'error-input'
                          : ''
                      }
                      onChange={event => {
                        const { name, value } = event.target;
                        setFieldTouched(name, true);
                        setFieldValue(name, value);
                      }}
                    />
                    <ErrorMessage
                      name='shipping_address'
                      component='span'
                      className='error'
                    />
                  </FormField>
                </Box>
              </Box>

              <Actions className='btn-container flex-justify-end'>
                <Button type='submit'>{'Update'}</Button>
                <Button
                  variant='text'
                  color='secondary'
                  className='cancel-btn'
                  onClick={handleClosePopUp}
                >
                Discard
                </Button>
              </Actions>
            </Content>
          )}
        </PopUpForm>
      </PopUpDialog>
      <WarningCard
        open={isErrorPopUpOpen}
        onClose={() => setIsErrorPopUpOpen(false)}
        message={errorMessage}
        severity='error'
        durationInSeconds={10}
      />
    </>
  );
};

export default CustomerEdit;
