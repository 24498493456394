import { AlertColor } from '@mui/material';
import { create } from 'zustand';

type Action = {
  setAlert: (error: string) => void;
  resetAlert: () => void;
};

type State = {
  message: string;
  severity: AlertColor;
  duration: number;
};

const useAlertStore = create<State & Action>()(set => ({
  message: '',
  severity: 'error',
  duration: 10,

  setAlert: (
    message: string,
    severity: AlertColor = 'error',
    duration = 10,
  ) => {
    set(() => ({
      message,
      severity,
      duration,
    }));
  },

  resetAlert: () => {
    set(() => ({
      message: '',
      severity: 'error',
      duration: 10,
    }));
  },
}));

export { useAlertStore };
