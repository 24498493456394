import { styled } from '@mui/system';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { mediaQuery } from 'theme/viewport';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 100,
    },
  },
};

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  width: '100%',
  [theme.breakpoints.up('lg')]: {
    width: 150,
  },
}));

const StyledSelect = styled(Select)({
  fontSize: '16px',
  [mediaQuery.down('lg')]: {
    fontSize: '12px',
  },
});

const StyledMenuItem = styled(MenuItem)({
  fontSize: '16px',
  [mediaQuery.down('lg')]: {
    fontSize: '12px',
  },
});

const StyledInputLabel = styled(InputLabel)({
  fontSize: '16px',
  [mediaQuery.down('lg')]: {
    fontSize: '12px',
  },
});


const StyledListItemText = styled(ListItemText)({
  fontSize: '16px',
  [mediaQuery.down('lg')]: {
    fontSize: '12px',
    ['& .MuiTypography-root']: {
      fontSize: 'inherit'
    }
  },
});

const StyledCheckBox = styled(Checkbox)({
  [mediaQuery.down('lg')]: {
    fontSize: '12px',
    padding: '5px',
  }
});

type DropDownFilterProps = {
  onChange: (selectedItems: string[]) => void;
  selectedFields: string[];
  inputFields: string[];
  label?: string;
};

export default function DropDownFilter({
  onChange,
  selectedFields,
  inputFields,
  label = 'Status',
}: DropDownFilterProps) {
  const handleChange = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value },
    } = event;
    const itemsSelected = Array.isArray(value) ? value : value.split(',');
    onChange(itemsSelected);
  };

  return (
    <StyledFormControl size='small'>
      <StyledInputLabel id='delivery-status-label'>{label}</StyledInputLabel>
      <StyledSelect
        labelId='delivery-status-label'
        id='delivery-status'
        multiple
        value={selectedFields}
        renderValue={(selected: unknown[]) => selected.join(', ')}
        input={<OutlinedInput label={label} />}
        onChange={handleChange}
        MenuProps={MenuProps}
      >
        {inputFields.map(name => (
          <StyledMenuItem key={name} value={name}>
            <StyledCheckBox checked={selectedFields.includes(name)} />
            <StyledListItemText
              primary={name.charAt(0).toUpperCase() + name.slice(1)}
            />
          </StyledMenuItem>
        ))}
      </StyledSelect>
    </StyledFormControl>
  );
}
