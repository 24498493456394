import { Button, Input } from 'views/components/elements';
import {
  FormField,
  Required,
  Actions,
  Content,
  PopUpForm,
} from 'views/pages/delivery/StyledComponent';
import {
  removeExtraSpace,
  supplierValidationSchema,
} from 'views/shared/utils/form-validator';
import { createSupplier } from 'gateways/supplier';
import { handleError } from 'views/shared/utils/error-utils';
import '../forms/index.scss';
import '../../delivery/forms/UserForm.scss';
import { ErrorMessage } from 'formik';
import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import PopUpDialog from 'views/components/elements/PopUpDialog';
import WarningCard from 'views/components/elements/WarningCard';
import { useLocation, useNavigate } from 'react-router-dom';
import React from 'react';
import { SupplierFormData } from 'models/Supplier';

interface SupplierAddProps {
  onSuccess?: () => void;
}

const SupplierAdd: React.FC<SupplierAddProps> = ({ onSuccess }) => {
  const [isPopUpOpen, setIsPopUpOpen] = useState(false);
  const [isErrorPopUpOpen, setIsErrorPopUpOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const navigate = useNavigate();

  // Function to remove query params from the URL
  const clearParam = () => {
    queryParams.delete('add');
    navigate(`/settings/suppliers?${queryParams.toString()}`);
  };

  useEffect(() => {
    // Check if the query param ?add=true is present
    const params = new URLSearchParams(location.search);
    if (params.get('add') === 'true') {
      setIsPopUpOpen(true);
    }
  }, [location]);

  const handleSubmit = async (supplierData: SupplierFormData) => {
    const newSupplierData = {
      ...supplierData,
      code: removeExtraSpace(supplierData.code),
      name: removeExtraSpace(supplierData.name),
      address: removeExtraSpace(supplierData.address),
      phone: removeExtraSpace(supplierData.phone),
      gst_number: removeExtraSpace(supplierData.gst_number),
    };

    try {
      const response = await createSupplier(newSupplierData);
      const { failed, error } = handleError(response);
      if (failed) {
        setErrorMessage(error || 'Failed to add supplier');
        setIsErrorPopUpOpen(true);
      } else {
        onSuccess?.();
        clearParam();
        setIsPopUpOpen(false);
      }
    } catch (error) {
      setErrorMessage('An error occurred while adding the supplier');
      setIsErrorPopUpOpen(true);
    }
  };

  const handleClosePopUp = () => {
    setIsPopUpOpen(false);
    clearParam();
  };

  return (
    <>
      <PopUpDialog
        open={isPopUpOpen}
        onClose={handleClosePopUp}
        title='Add Supplier'
        sx={{ maxWidth: '880px', maxHeight: 'max-content' }}
      >
        <PopUpForm
          className='user-form form'
          initialValues={{
            code: '',
            name: '',
            phone: '',
            gst_number: '',
            address: '',
          }}
          validationSchema={supplierValidationSchema}
          validateOnChange={true}
          validateOnBlur={true}
          onSubmit={handleSubmit}
          onReset={handleClosePopUp}
        >
          {({ errors, touched, setFieldTouched, setFieldValue }) => (
            <Content>
              <Box className='form-container' style={{ display: 'flex' }}>
                <Box style={{ width: '50%', paddingRight: '1rem' }}>
                  <FormField
                    className='form-group'
                    style={{ marginBottom: '18px' }}
                  >
                    <label>
                      Supplier Code <Required>*</Required>
                    </label>
                    <Input
                      name='code'
                      placeholder='Supplier Code'
                      className={
                        touched.code && errors.code ? 'error-input' : ''
                      }
                      onChange={event => {
                        const { name, value } = event.target;
                        setFieldTouched(name, true);
                        setFieldValue(name, value);
                      }}
                    />
                    <ErrorMessage
                      name='code'
                      component='span'
                      className='error'
                    />
                  </FormField>
                  <FormField
                    className='form-group'
                    style={{ marginBottom: '18px' }}
                  >
                    <label>
                      Phone 
                    </label>
                    <Input
                      name='phone'
                      placeholder='Phone'
                      className={
                        touched.phone && errors.phone ? 'error-input' : ''
                      }
                      onChange={event => {
                        const { name, value } = event.target;
                        setFieldTouched(name, true);
                        setFieldValue(name, value);
                      }}
                    />
                    <ErrorMessage
                      name='phone'
                      component='span'
                      className='error'
                    />
                  </FormField>
                  <FormField
                    className='form-group'
                    style={{ marginBottom: '18px' }}
                  >
                    <label>
                      Address <Required>*</Required>
                    </label>
                    <Input
                      name='address'
                      placeholder='Address'
                      className={
                        touched.address && errors.address
                          ? 'error-input'
                          : ''
                      }
                      onChange={event => {
                        const { name, value } = event.target;
                        setFieldTouched(name, true);
                        setFieldValue(name, value);
                      }}
                    />
                    <ErrorMessage
                      name='address'
                      component='span'
                      className='error'
                    />
                  </FormField>
                </Box>
                <Box style={{ width: '50%', paddingLeft: '1rem' }}>
                  <FormField
                    className='form-group'
                    style={{ marginBottom: '18px' }}
                  >
                    <label>
                      Supplier Name <Required>*</Required>
                    </label>
                    <Input
                      name='name'
                      placeholder='FirstName LastName'
                      className={
                        touched.name && errors.name ? 'error-input' : ''
                      }
                      onChange={event => {
                        const { name, value } = event.target;
                        setFieldTouched(name, true);
                        setFieldValue(name, value);
                      }}
                    />
                    <ErrorMessage
                      name='name'
                      component='span'
                      className='error'
                    />
                  </FormField>
                  <FormField
                    className='form-group'
                    style={{ marginBottom: '18px' }}
                  >
                    <label>GST Number</label>
                    <Input
                      name='gst_number'
                      placeholder='GST Number'
                      className={
                        touched.gst_number && errors.gst_number
                          ? 'error-input'
                          : ''
                      }
                      onChange={event => {
                        const { name, value } = event.target;
                        setFieldTouched(name, true);
                        setFieldValue(name, value);
                      }}
                    />
                    <ErrorMessage
                      name='gst_number'
                      component='span'
                      className='error'
                    />
                  </FormField>
                </Box>
              </Box>
              <Actions className='btn-container flex-justify-end'>
                <Button type='submit'>{'Add Supplier'}</Button>
                <Button
                  variant='text'
                  color='secondary'
                  className='cancel-btn'
                  onClick={handleClosePopUp}
                >
                  Discard
                </Button>
              </Actions>
            </Content>
          )}
        </PopUpForm>
      </PopUpDialog>
      <WarningCard
        open={isErrorPopUpOpen}
        onClose={() => setIsErrorPopUpOpen(false)}
        message={errorMessage}
        severity='error'
        durationInSeconds={10}
      />
    </>
  );
};

export default SupplierAdd;
