import {
  Button,
  Form,
  Input,
  StepWizardProps,
  Textarea,
} from 'views/components/elements';
import {
  Box,
  styled,
  Typography,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { FormField } from '../delivery/StyledComponent';
import { ErrorMessage } from 'formik';
import { productValidationSchema } from 'views/shared/utils/form-validator';
import './inbound-import.css';
import { Product, ProductGroup, UOM } from 'models';
import { UOMSearchableDropDown } from './dropdowns/UOMSearchableDropDown';
import { SupplierSearchableDropDown } from './dropdowns/SupplierSearchableDropDown';
import { ProductGroupSearchableDropDown } from './dropdowns/ProductGroupSearchableDropDown';
import {
  fetchProductGroupById,
  fetchUomById,
  updateProduct,
} from 'gateways/product';
import { handleError } from 'views/shared/utils/error-utils';
import { useAlertStore } from 'store/alert';
import { useEffect, useState } from 'react';
import { Supplier } from 'models/Supplier';
import { getSupplierById } from 'gateways/supplier';
import { ProductFormData } from 'models/Form';
import { removeEmptyValues } from 'views/shared/utils/string-utils';

const PopUpForm = styled(Form)({
  width: '100%',
});
const Content = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  rowGap: 12,
});
const CheckboxContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  gap: '5px',
  justifyContent: 'space-between',
  fontFamily: 'Rubik',
  fontSize: '14px',
  fontWeight: '400',
  gridColumn: '1/-1',
});

interface AdditionalInformationProps extends StepWizardProps {
  totalNewProducts: number;
  currentProductIndex: number;
  currentProduct: Product;
  onSubmit: (index: number) => void;
}

interface ProductDropDownType {
  supplier?: Supplier;
  productGroup?: ProductGroup;
  uom?: UOM;
}

const defaultDropDownValue: ProductDropDownType = {
  supplier: null,
  productGroup: null,
  uom: null,
};

const AdditionalInformation: React.FC<AdditionalInformationProps> = ({
  currentProduct,
  currentProductIndex,
  totalNewProducts,
  goToNamedStep,
  onSubmit,
}) => {
  const { setAlert } = useAlertStore();

  const [dropdownData, setDropdownData] =
    useState<ProductDropDownType>(defaultDropDownValue);

  const doInitialSetup =  async () => {
    if (currentProduct) {
      const { supplier_id, product_group_id, uom_id } = currentProduct;
      const { data: supplier } = !currentProduct.supplier_id
        ? { data: null }
        : handleError<Supplier>(await getSupplierById({ supplier_id }));

      const { data: productGroup } = !currentProduct.product_group_id
        ? { data: null }
        : handleError<ProductGroup>(
          await fetchProductGroupById(product_group_id)
        );

      const { data: uom } = !currentProduct.uom_id
        ? { data: null }
        : handleError<UOM>(await fetchUomById(uom_id));

      setDropdownData({
        supplier,
        productGroup,
        uom,
      });
    }
  };

  useEffect(() => {
    doInitialSetup();

    return () => {
      setDropdownData(defaultDropDownValue);
    };
  }, [currentProductIndex]);

  const handleSubmit = async (values: Product) => {
    const productData = removeEmptyValues({
      ...currentProduct,
      ...values,
    }) as unknown as Product;

    const minLifeOnReceipt = parseInt(productData.min_life_on_receipt as unknown as string) || undefined;
    const minLifeOnDespatch = parseInt(productData.min_life_on_despatch as unknown as string) || undefined;

    const apiResponse = await updateProduct({
      ...productData,
      min_life_on_despatch: minLifeOnDespatch,
      min_life_on_receipt: minLifeOnReceipt,
    });
    const { error, failed } = handleError(apiResponse);

    if (failed) {
      return setAlert(error);
    }

    if (currentProductIndex === totalNewProducts) {
      goToNamedStep('summary-report');
    } else {
      onSubmit(currentProductIndex);
      goToNamedStep('additional-info');
    }
  };

  const handleSkip = () => goToNamedStep('summary-report');

  if (!currentProduct) {
    return null;
  }

  const formValue: ProductFormData = {
    uom_id: currentProduct.uom_id || '',
    is_fragile: Boolean(currentProduct.is_fragile),
    is_hazardous: Boolean(currentProduct.is_hazardous),
    is_heavy: Boolean(currentProduct.is_heavy),
    is_liquids: Boolean(currentProduct.is_liquids),
    is_perishable: Boolean(currentProduct.is_perishable),
    product_id: currentProduct.product_id || '',
    hsn_code: currentProduct.hsn_code || '',
    image_url: currentProduct.image_url || '',
    long_description: currentProduct.long_description || '',
    min_life_on_despatch: currentProduct.min_life_on_despatch || '',
    min_life_on_receipt: currentProduct.min_life_on_receipt || '',
    product_group_id: currentProduct.product_group_id || '',
    supplier_id: currentProduct.supplier_id || '',
  };

  return (
    <>
      <Box
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '16px',
          width: 880,
          marginBottom: '16px',
        }}
      >
        <Box
          sx={{
            backgroundColor: '#f3e8ff',
            padding: '4px 8px',
            borderRadius: '4px',
            display: 'flex',
            alignItems: 'center',
            fontWeight: 'bold',
          }}
        >
          <Typography variant='body2' style={{ fontWeight: 500 }}>
            {currentProductIndex}/{totalNewProducts}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
          <Typography variant='body1' style={{ fontWeight: 400 }}>
            DAC01524
          </Typography>
          <Typography variant='body1' style={{ fontWeight: 400 }}>
            - Dabur Red Paste
          </Typography>
        </Box>
      </Box>
      <PopUpForm
        className='user-form form'
        initialValues={formValue}
        enableReinitialize={true}
        validationSchema={productValidationSchema}
        validateOnChange={true}
        validateOnBlur={true}
        onSubmit={handleSubmit}
      >
        {({ values, errors, touched, setFieldTouched, setFieldValue }) => (
          <Content>
            <Box
              className='form-container'
              style={{
                display: 'grid',
                gridTemplateColumns: 'repeat(2, 1fr)',
                gap: '1rem',
              }}
            >
              <FormField className='form-group'>
                <label className='labelStyles'>UOM</label>
                <UOMSearchableDropDown
                  value={dropdownData.uom}
                  onSelect={uom => {
                    setFieldTouched('uom_id', true);
                    setFieldValue('uom_id', uom.uom_id);
                  }}
                />
              </FormField>
              <FormField className='form-group'>
                <label className='labelStyles'>Product Group</label>
                <ProductGroupSearchableDropDown
                  value={dropdownData.productGroup}
                  onSelect={productGroup => {
                    setFieldTouched('product_group_id', true);
                    setFieldValue(
                      'product_group_id',
                      productGroup.product_group_id
                    );
                  }}
                />
              </FormField>
              <FormField className='form-group'>
                <label className='labelStyles'>HSN Code</label>
                <Input
                  name='hsn_code'
                  placeholder='HSN Code'
                  className={
                    touched.hsn_code && errors.hsn_code ? 'error-input' : ''
                  }
                  onChange={event => {
                    const { name, value } = event.target;
                    setFieldTouched(name, true);
                    setFieldValue(name, value);
                  }}
                />
                <ErrorMessage
                  name='hsn_code'
                  component='span'
                  className='error'
                />
              </FormField>
              <FormField className='form-group'>
                <label className='labelStyles'>Image URL</label>
                <Input
                  name='image_url'
                  placeholder='Image URL'
                  className={
                    touched.image_url && errors.image_url ? 'error-input' : ''
                  }
                  onChange={event => {
                    const { name, value } = event.target;
                    setFieldTouched(name, true);
                    setFieldValue(name, value);
                  }}
                />
                <ErrorMessage
                  name='image_url'
                  component='span'
                  className='error'
                />
              </FormField>
              <CheckboxContainer>
                {['Liquids', 'Heavy', 'Perishable', 'Hazardous', 'Fragile'].map(
                  label => {
                    const field = `is_${label.toLowerCase()}`;

                    return (
                      <FormControlLabel
                        key={label}
                        className='labelStyles'
                        control={
                          <Checkbox
                            checked={values[field]}
                            onChange={event => {
                              setFieldTouched(field, true);
                              setFieldValue(field, event.target.checked);
                            }}
                            name={field}
                            sx={{
                              '& .MuiSvgIcon-root': {
                                fill: '#BBB9B9',
                              },
                            }}
                          />
                        }
                        label={label}
                      />
                    );
                  }
                )}
              </CheckboxContainer>
              <FormField className='form-group'>
                <label className='labelStyles'>Min Life on Receipt</label>
                <Input
                  name='min_life_on_receipt'
                  placeholder='Minimum life on receipt'
                  className={
                    touched.min_life_on_receipt && errors.min_life_on_receipt
                      ? 'error-input'
                      : ''
                  }
                  onChange={event => {
                    const { name, value } = event.target;
                    setFieldTouched(name, true);
                    setFieldValue(name, value);
                  }}
                />
                <ErrorMessage
                  name='min_life_on_receipt'
                  component='span'
                  className='error'
                />
              </FormField>
              <FormField className='form-group'>
                <label className='labelStyles'>Min Life on Despatch</label>
                <Input
                  name='min_life_on_despatch'
                  placeholder='Minimum life on despatch'
                  className={
                    touched.Min_Life_on_Despatch && errors.Min_Life_on_Despatch
                      ? 'error-input'
                      : ''
                  }
                  onChange={event => {
                    const { name, value } = event.target;
                    setFieldTouched(name, true);
                    setFieldValue(name, value);
                  }}
                />
                <ErrorMessage
                  name='min_life_on_despatch'
                  component='span'
                  className='error'
                />
              </FormField>
              <FormField className='form-group'>
                <label className='labelStyles'>Supplier</label>
                <SupplierSearchableDropDown
                  value={dropdownData.supplier}
                  onSelect={supplier => {
                    setFieldTouched('supplier_id', true);
                    setFieldValue('supplier_id', supplier.supplier_id);
                  }}
                />
              </FormField>
              <FormField className='form-group'>
                <label className='labelStyles'>Long Description</label>
                <Textarea
                  name='long_description'
                  placeholder='Long Description'
                  style={{ height: '100px' }}
                  rows={4}
                  className={
                    touched.long_description && errors.long_description
                      ? 'error-input'
                      : ''
                  }
                  onChange={event => {
                    const { name, value } = event.currentTarget;
                    setFieldTouched(name, true);
                    setFieldValue(name, value);
                  }}
                />
                <ErrorMessage
                  name='long_description'
                  component='span'
                  className='error'
                />
              </FormField>
            </Box>
            <Box mt={3} display='flex' justifyContent='right' gap={2}>
              <Button
                variant='contained'
                color='primary'
                size='small'
                type='submit'
                sx={{ textTransform: 'none' }}
              >
                Next
              </Button>
              <Button
                onClick={handleSkip}
                variant='text'
                size='small'
                color='secondary'
                className='cancel-btn'
                sx={{
                  textTransform: 'none',
                  color: '#323130',
                  border: '1px solid #8A8886',
                }}
              >
                Skip All
              </Button>
            </Box>
          </Content>
        )}
      </PopUpForm>
    </>
  );
};

export default AdditionalInformation;
