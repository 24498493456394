import { Link, useLocation } from 'react-router-dom';
import InboundImport from './inbound-import';
import { Button, Column, Form, Input, Table } from 'views/components/elements';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Replay as RefreshIcon,
  Search as SearchIcon,
  Cached as ResetIcon,
} from '@mui/icons-material';
import { Action, FlexRow } from 'views/components/styled';
import WarningCard from 'views/components/elements/WarningCard';
import { PenSquareIcon } from 'views/components/icon';
import { Inbound, InboundType } from 'models';
import { useInboundStore } from 'store/inbound';
import { CircularProgress, Tooltip } from '@mui/material';
import { DashBoardList, TextDashBoardList } from '../delivery/dashboard';
import { DashBoardDatePickerField } from 'views/pages/delivery/dashboard/dashboard-date-picker';
import { CustomDateRangeOptions } from 'views/pages/delivery/dashboard/reusables';
import { getFormattedDate } from 'views/shared/utils/string-utils';
import CustomMenu from 'views/components/elements/CustomMenu';
import styled from '@emotion/styled';
import './inbound-list.scss';

type MappedInboundType = { [key in InboundType]: string[] };

const DATE_FORMAT = 'YYYY-MM-DD';

const COMMON_HIDDEN_FIELDS = [
  'inbound_end_time',
  'inbound_start_time',
];

const HIDDEN_FIELDS: MappedInboundType = {
  supplier: ['description', ...COMMON_HIDDEN_FIELDS, 'vehicle_number', 'transporter'],
  transfer: [...COMMON_HIDDEN_FIELDS],
  return: ['description', ...COMMON_HIDDEN_FIELDS],
};

const FIELDS_TO_SHOW: MappedInboundType = {
  supplier: [
    'inbound_number',
    'supplier',
    'warehouse',
    'source_location',
    'order_number',
    'gr_number',
    'comments',
    'inbound_status',
  ],
  return: [
    'inbound_number',
    'customer',
    'warehouse',
    'source_location',
    'rma_reference',
    'shipping_reference',
    'comments',
    'inbound_status',
  ],
  transfer: [
    'inbound_number',
    'warehouse',
    'source_warehouse',
    'source_location',
    'order_number',
    'comments',
    'description',
    'inbound_status',
  ],
};

const TableHolderDiv = styled('div')({
  position: 'relative',
});

const AbsolutePositionedDiv = styled('div')({
  position: 'absolute',
  top: '12px',
  right: '12px',
});

type InboundKeys = keyof Omit<
  Inbound,
  | 'inbound_id'
  | 'type_id'
  | 'source_warehouse_id'
  | 'supplier_id'
  | 'customer_id'
  | 'warehouse_id'
  | 'business_id'
  | 'status_id'
  | 'created_date_time'
  | 'updated_date_time'
  | 'inbound_type'
  | 'line_status'
  | 'lines'
>;

const INBOUND_COLUMNS: {
  [key in InboundKeys]: Column<Inbound>;
} = {
  inbound_number: {
    field: 'inbound_number',
    label: 'Inbound Number',
    sortable: true,
    width: '22ch',
  },
  supplier: {
    field: 'supplier',
    label: 'supplier',
    width: '10ch',
    valueGetter: ({ supplier: { name } }) => <Tooltip title={name} placement='bottom-start'>
      <p  className='truncate' style={{ maxWidth: '12ch' }}>{name}</p>
    </Tooltip>,
  },
  warehouse: {
    field: 'warehouse',
    label: 'Warehouse Name',
    width: '20ch',
    valueGetter: ({ warehouse: { warehouse_name } }) => <Tooltip title={warehouse_name} placement='bottom-start'>
      <p  className='truncate' style={{ maxWidth: '20ch' }}>{warehouse_name}</p>
    </Tooltip>,
  },
  source_location: {
    field: 'source_location',
    label: 'Source Location',
    width: '18ch',
  },
  order_number: {
    field: 'order_number',
    label: 'Order Number',
    width: '22ch',
  },
  gr_number: {
    field: 'gr_number',
    label: 'GR Number',
    width: '22ch',
  },
  inbound_status: {
    field: 'inbound_status',
    label: 'Status',
    width: '12ch',
    valueGetter: ({inbound_status}) => (
      <p className={`status ${inbound_status.toLowerCase()}`}>
        {inbound_status}
      </p>
    ),
  },
  inbound_start_time: {
    field: 'inbound_start_time',
    label: 'Inbound Start Time',
    width: '20ch',
    format: 'date',
  },
  inbound_end_time: {
    field: 'inbound_end_time',
    label: 'Inbound End Time',
    width: '20ch',
    format: 'date',
  },
  rma_reference: {
    field: 'rma_reference',
    label: 'RMA Reference',
    width: '22ch',
  },
  shipping_reference: {
    field: 'shipping_reference',
    label: 'Shipping Reference',
    width: '22ch',
  },
  customer: {
    field: 'customer',
    label: 'Customer Name',
    width: '20ch',
    valueGetter: ({ customer: { name } }) => <Tooltip title={name} placement='bottom-start'>
      <p  className='truncate' style={{ maxWidth: '20ch' }}>{name}</p>
    </Tooltip>,
  },
  source_warehouse: {
    field: 'source_warehouse',
    label: 'Source Warehouse',
    width: '20ch',
    valueGetter: ({ source_warehouse }) => <Tooltip title={source_warehouse} placement='bottom-start'>
      <p className='truncate' style={{ maxWidth: '20ch' }}>{source_warehouse}</p>
    </Tooltip>,
  },
  comments: {
    field: 'comments',
    label: 'Comments',
    width: '12ch',
    valueGetter: ({comments}) => <Tooltip title={comments} placement='bottom-start'>
      <p  className='truncate' style={{ maxWidth: '16ch' }}>{comments}</p>
    </Tooltip>,
  },
  description: {
    field: 'description',
    label: 'Description',
    width: '16ch',
    valueGetter: ({description}) => <Tooltip title={description} placement='bottom-start'>
      <p  className='truncate' style={{ maxWidth: '16ch' }}>{description}</p>
    </Tooltip>,
  },
  vehicle_number: {
    field: 'vehicle_number',
    label: 'Vehicle Number',
    width: '20ch',
  },
  transporter: {
    field: 'transporter',
    label: 'Transporter',
    width: '20ch',
    valueGetter: ({transporter}) => <Tooltip title={transporter} placement='bottom-start'>
      <p className='truncate' style={{ maxWidth: '20ch' }}>{transporter}</p>
    </Tooltip>,
  },
};

const InboundList = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const isImport = queryParams.get('import') === 'true';
  const query = queryParams.get('query') || undefined;
  const isResetDisabled = !query;

  const navigate = useNavigate();

  const fields = (queryParams.get('fields')?.split(',') || []).filter(
    field => field.length
  );
  const inboundType: InboundType =
    (queryParams.get('type') as InboundType) || 'supplier';
  const status = queryParams.get('status') || '';
  const label = (queryParams.get('label') ||
    'view-all') as CustomDateRangeOptions;
  const fromDate = queryParams.get('fromDate') || undefined;
  const toDate = queryParams.get('toDate') || undefined;
  const { from, to } = getFormattedDate(DATE_FORMAT, {
    label,
    from: fromDate,
    to: toDate,
  });

  const [sortConfig, setSortConfig] = useState({
    field: '',
    order: null,
  });

  const {
    inbounds,
    fetchInboundsPaginated,
    fetchInboundTransactionDetail,
    aggregatedData,
    hasNextPage,
    filter,
    fetching,
    error,
    setError,
    resetInboundData,
  } = useInboundStore();

  const formRef = useRef(null);

  useEffect(() => {
    if (inbounds.length === 0) {
      fetchInboundsPaginated({
        query,
        toDate,
        fromDate,
        statuses: [status],
        type: inboundType
      });
    }

    return () => resetInboundData();
  }, []);

  useEffect(() => {
    fetchInboundsPaginated(
      {
        query,
        toDate,
        fromDate,
        statuses: [status],
        type: inboundType
      },
    );
  }, [location]);

  useEffect(() => {
    fetchInboundTransactionDetail(from, to);
  }, [from, to]);

  const handleResetForm = () => {
    if (formRef.current) {
      formRef.current.resetForm({ values: { query: '' } });
    }

    queryParams.delete('query');
  };

  const handleSearch = (values: typeof filter) => {
    queryParams.set('query', values.query ?? '');
    navigate(`/inbound?${queryParams.toString()}`);
  };

  const handleInboundTypeChange = (key: string) => {
    queryParams.set('type', key);
    queryParams.delete('fields');
    handleResetForm();

    navigate(`/inbound?${queryParams.toString()}`);
  };

  const handleRefresh = () => {
    handleResetForm();

    navigate('/inbound');
  };

  const handleStatusChange = (key: string) => {
    queryParams.set('status', key);
    navigate(`/inbound?${queryParams.toString()}`);
  };

  const handleDateFilterChange = (
    label: CustomDateRangeOptions,
    fromDate: Date,
    toDate: Date
  ) => {
    const { from, to } = getFormattedDate(DATE_FORMAT, {
      label,
      from: fromDate,
      to: toDate,
    });

    from ? queryParams.set('fromDate', from) : queryParams.delete('fromDate');
    to ? queryParams.set('toDate', to) : queryParams.delete('toDate');

    queryParams.set('label', label);
    navigate(`/inbound?${queryParams.toString()}`);
  };

  const sortedInbounds = [...inbounds].sort((a, b) => {
    if (a[sortConfig.field] < b[sortConfig.field])
      return sortConfig.order === 'asc' ? -1 : 1;
    if (a[sortConfig.field] > b[sortConfig.field])
      return sortConfig.order === 'asc' ? 1 : -1;
    return 0;
  });

  const handleNextPage = () => {
    const newFilter = Object.assign({}, filter, {
      page: filter.page + 1,
    });

    fetchInboundsPaginated(newFilter);
  };

  const handleReset = () => {
    handleResetForm();
    navigate(`/inbound?${queryParams.toString()}`);
  };

  const noRecordFoundMsg = ((status: string) => {
    switch (status) {
    case 'supplier':
      return 'No supplier inbound found.';
    case 'transfer':
      return 'No inbound transfer found.';
    case 'return':
      return 'No return found.';
    }
  })(inboundType);

  // Handle Table columns and hidden menu based on the inbound type
  const menuOptions = HIDDEN_FIELDS[inboundType].map((label, index) => ({
    id: `${index + 1}`,
    label: label.replace(/_/g, ' '),
  }));

  const defaultColumnsToShow: Column<Inbound>[] = FIELDS_TO_SHOW[
    inboundType
  ].map(field => INBOUND_COLUMNS[field]);
  const extraColumnsToShow = [];

  if (fields.length) {
    const fieldSet = new Set(fields);
    const fieldsToShow = menuOptions
      .filter(({ id }) => fieldSet.has(id))
      .map(({ label }) => INBOUND_COLUMNS[label.replace(/\s/g, '_')]);
    extraColumnsToShow.push(...fieldsToShow);
  }

  const tableColumns: Column<Inbound>[] = [
    ...defaultColumnsToShow,
    ...extraColumnsToShow,
  ];

  const handleMenuChange = (options: string[]) => {
    const optionsString = options.toString();
    const fieldsString = fields.toString();

    if (optionsString === fieldsString) {
      return;
    }

    if (!optionsString) {
      queryParams.delete('fields');
    } else {
      queryParams.set('fields', optionsString);
    }

    navigate(`/inbound?${queryParams.toString()}`);
  };

  return (
    <div className='custom-table-wrapper inbound-list'>
      {isImport && <InboundImport />}
      <WarningCard
        open={Boolean(error)}
        onClose={() => setError(null)}
        message={error}
        severity='error'
        durationInSeconds={10}
      />
      <div className='head' style={{ marginBottom: '3rem' }}>
        <FlexRow style={{ gap: '1rem' }}>
          <h1>Inbound</h1>
          <DashBoardList
            data={aggregatedData as any}
            onChange={handleInboundTypeChange}
            selectedField={inboundType}
          >
            <DashBoardDatePickerField
              label={label}
              from={from as unknown as Date}
              to={to as unknown as Date}
              onChange={handleDateFilterChange}
            />
          </DashBoardList>
          <div style={{ marginLeft: 'auto' }}>
            <TextDashBoardList
              data={['received', 'expected', 'advanced']}
              onChange={handleStatusChange}
              selectedField={status}
            />
          </div>
        </FlexRow>
      </div>

      <Action>
        <Form
          innerRef={formRef}
          initialValues={{ query: query ?? '' }}
          onSubmit={handleSearch}
          validateOnChange={false}
        >
          <Input
            name='query'
            placeholder='Search Inbounds'
            startAdornment={<SearchIcon />}
          />
          <Button type='submit'>
            <SearchIcon />
          </Button>
          <Button
            variant='text'
            startIcon={
              <span id='package-icon'>
                <RefreshIcon />
              </span>
            }
            onClick={handleReset}
            disabled={isResetDisabled}
          >
            Reset
          </Button>
          <Button
            variant='text'
            startIcon={
              <span id='package-icon'>
                <ResetIcon />
              </span>
            }
            onClick={handleRefresh}
          >
            Refresh
          </Button>
        </Form>

        <FlexRow>
          <Button
            component={Link}
            variant='contained'
            style={{ height: '40px' }}
            to='/inbound?import=true'
          >
            SAP Import
          </Button>
          <Button
            component={Link}
            variant='contained'
            style={{ height: '40px' }}
            to='/inbound?import=true'
            sx={{ textTransform: 'capitalize' }}
          >{`Add ${inboundType === 'supplier' ? 'inbound' : inboundType}
          `}</Button>
        </FlexRow>
      </Action>

      {fetching && (
        <div className='spinner'>
          <CircularProgress />
        </div>
      )}

      <TableHolderDiv>
        <AbsolutePositionedDiv>
          <CustomMenu
            options={menuOptions}
            onSelectionChange={handleMenuChange}
            defaultSelectedOptions={fields}
          >
            <PenSquareIcon />
          </CustomMenu>
        </AbsolutePositionedDiv>
        <div style={{ width: 'calc(100% - 50px)', overflow: 'auto', height: '100%' }}>
          <Table
            rows={sortedInbounds}
            columns={tableColumns}
            infiniteScroll
            hasMoreRows={hasNextPage}
            sortingField={sortConfig.field}
            sortingOrder={sortConfig.order}
            onSortChange={(field, order) => setSortConfig({ field, order })}
            onNextPage={handleNextPage}
            stickyHeader
          />

          {!fetching && inbounds.length === 0 && (
            <div className='margin-top-24px text-center'>
              {noRecordFoundMsg}
            </div>
          )}
        </div>
      </TableHolderDiv>
    </div>
  );
};

export default InboundList;
