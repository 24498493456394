import { ApiParams, fetchSuppliersList } from 'gateways/warehouse';
import { Supplier } from 'models/Supplier';
import { removeEmptyValues } from 'views/shared/utils/string-utils';
import { create } from 'zustand';

type Action = {
  fetchSuppliersPaginated: (
    params?: ApiParams,
    refetch?: boolean
  ) => Promise<void>;
  setError?: (error: any) => void;
};

type State = {
  suppliers: Supplier[];
  hasNextPage: boolean;
  filter: ApiParams;
  fetching?: boolean;
  error?: any;
};

const useSupplierStore = create<State & Action>()(set => ({
  suppliers: [],
  hasNextPage: false,
  filter: {},
  error: null,
  fetching: false,
  selectedCustomer: null,

  fetchSuppliersPaginated: async (
    params?: ApiParams,
    refetch?: boolean
  ): Promise<void> => {
    const apiParams = removeEmptyValues(params) ?? {};

    apiParams.limit ??= 10;
    apiParams.page ??= 1;

    set(() => ({
      fetching: true,
    }));
    try {
      const apiResponse = await fetchSuppliersList(apiParams);
      set(state => ({
        filter: apiParams,
        // for search, skip pagination
        suppliers:
          apiParams.query || apiParams.page === 1 || refetch
            ? apiResponse.collection
            : state.suppliers.concat(apiResponse.collection),
        hasNextPage: apiParams.page < apiResponse.metadata.last_page,
      }));
    } catch (err) {
      set(() => ({
        error: 'Failed to fetch suppliers',
      }));
    }
    set(() => ({
      fetching: false,
    }));
  },

  setError: (error: any) => {
    set(() => ({
      error,
    }));
  },
}));

export { useSupplierStore };
