import { Menu, MenuItem } from '@mui/material';
import { useRef, useState } from 'react';
import { DatePickerIcon } from 'views/components/icon';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  calculateDateRange,
} from 'views/shared/utils/string-utils';
import {
  CustomDateRangeOptions,
  CustomDateRangeValues,
  DashBoardContentTag,
  DashBoardFieldTag,
  DashBoardIconHolderTag,
} from './reusables';
import moment from 'moment';
import { DateRange } from 'react-day-picker';
import 'react-day-picker/style.css';
import {
  CustomDateRangePicker,
  PopoverWithArrow,
} from 'views/components/elements';
import styled from '@emotion/styled';

interface DatePickerMenuItem {
  key: CustomDateRangeOptions;
  label: CustomDateRangeValues;
}

interface DatePickerFieldProps extends DateRange {
  label: CustomDateRangeOptions;
  onChange: (value: CustomDateRangeOptions, from: Date, to: Date) => void;
}

const StyledMenuItem = styled(MenuItem)({
  color: '#3460DC',
  fontWeight: 500,
});

const DATE_FORMAT = 'DD/MM/YYYY';

const MenuItems: DatePickerMenuItem[] = [
  {
    key: 'view-all',
    label: 'View All',
  },
  {
    key: 'today',
    label: 'Today',
  },
  {
    key: 'last-7-days',
    label: 'Last 7 Days',
  },
  {
    key: 'custom-date',
    label: 'Custom Date',
  },
];

const DashBoardDatePickerField: React.FC<DatePickerFieldProps> = ({
  label,
  from,
  to,
  onChange,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const [isDatePickerOpen, setIsDatePickerOpen] = useState<HTMLElement | null>(
    null
  );
  const dashboardFieldRef = useRef(null);

  const handleClick: React.MouseEventHandler<HTMLDivElement> = event =>
    setAnchorEl(event.currentTarget);

  const handleClose = () => setAnchorEl(null);

  const handleMenuChange =
    ({ key }: DatePickerMenuItem) =>
      () => {
        setAnchorEl(null);
        if (key === 'custom-date') {
          return setIsDatePickerOpen(dashboardFieldRef.current);
        }

        if (key === 'view-all') {
          return onChange('view-all', null, null);
        }

        const range = calculateDateRange(key);
        const from = range.from.toDate();
        const to = range.to.toDate();

        onChange(key, from, to);
      };

  const menuItems = MenuItems.filter(
    function menuItemFilter({ label }) {
      return label !== this.label;
    },
    { label }
  ).map(({ key, label }) => (
    <StyledMenuItem key={key} onClick={handleMenuChange({ key, label })}>
      {label}
    </StyledMenuItem>
  ));

  const handleDateRangeCancel = () => setIsDatePickerOpen(null);
  const handleDateRangeApply = ({ from, to }: DateRange) => {
    setIsDatePickerOpen(null);
    onChange('custom-date', from, to);
  };

  const getDateFormatter =
    (formatter: string) =>
      (date: Date): string =>
        moment(date).format(formatter);

  const calculatedDateRange =
    label !== 'custom-date' && label !== 'view-all'
      ? calculateDateRange(label)
      : { from, to };

  const formattedFromDate = moment(calculatedDateRange.from).format(
    DATE_FORMAT
  );
  const formattedToDate = moment(calculatedDateRange.to).format(DATE_FORMAT);

  const value =
    label === 'today'
      ? formattedFromDate
      : label === 'view-all'
        ? ''
        : `${formattedFromDate} - ${formattedToDate}`;

  const selectedLabel = MenuItems.filter(
    function ({ key }) {
      return key === this.key;
    },
    { key: label }
  )[0].label;

  return (
    <>
      <DashBoardFieldTag
        onClick={handleClick}
        style={{ flex: '0 0 300px' }}
        ref={dashboardFieldRef}
      >
        <DashBoardIconHolderTag sx={{ backgroundColor: '#F3E8FF' }}>
          <DatePickerIcon />
        </DashBoardIconHolderTag>
        <DashBoardContentTag style={{ flexGrow: 1 }}>
          <span className='label'>{selectedLabel}</span>
          <strong className='value'>{value}</strong>
        </DashBoardContentTag>
        <ExpandMoreIcon style={{ justifySelf: 'end', color: '#9C9C9C' }} />
      </DashBoardFieldTag>
      <Menu
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        sx={{ ['& .MuiPaper-root']: { width: '300px' } }}
      >
        {menuItems}
      </Menu>
      <PopoverWithArrow
        anchorEl={isDatePickerOpen}
        setAnchorEl={setIsDatePickerOpen}
        content={
          <CustomDateRangePicker
            from={from}
            to={to}
            dateFormatter={getDateFormatter(DATE_FORMAT)}
            onApply={handleDateRangeApply}
            onCancel={handleDateRangeCancel}
          />
        }
      >
        <></>
      </PopoverWithArrow>
    </>
  );
};

export { DashBoardDatePickerField };
