import { ApiResponse, CreateInboundResponseDto, Inbound } from 'models';
import { sendRequest, getRequest, putRequest, postRequest } from './gateway';
import { ApiParams } from './warehouse';

function fetchInboundList(params: ApiParams) {
  const fetchPromise = getRequest<ApiResponse<Inbound[]>>('/v1/inbound/list', {
    params,
  });

  return sendRequest(fetchPromise);
}

function fetchInboundTypeTransactionDetail(params: ApiParams) {
  const fetchPromise = getRequest<ApiResponse<Inbound[]>>(
    '/v1/inbound/transaction',
    {
      params,
    }
  );

  return sendRequest(fetchPromise);
}

function fetchInbound(inbound_id: string) {
  const fetchPromise = getRequest<ApiResponse<Inbound>>(
    `/v1/inbound/${inbound_id}`
  );

  return sendRequest(fetchPromise);
}

function updateInbound({
  inbound_id,
  order_number,
  gr_number,
  comments,
  description,
  shipping_reference,
  transporter,
  vehicle_number,
}: Inbound) {
  const putPromise = putRequest<ApiResponse<Inbound>>(
    `/v1/inbound/${inbound_id}`,
    {
      order_number,
      gr_number,
      comments,
      description,
      shipping_reference,
      transporter,
      vehicle_number,
    }
  );

  return sendRequest(putPromise);
}

function createInbound(url: string): Promise<CreateInboundResponseDto> {
  const postPromise = postRequest<CreateInboundResponseDto>('/v1/inbound', {sap_import_url: url});

  return sendRequest(postPromise);
}

function sapImport<T>(file: File, setProgress?: (progress: number) => void) {
  const formData = new FormData();
  formData.append('file', file);

  const postPromise = postRequest<T>('/v1/file', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    onUploadProgress(progressEvent) {
      if (setProgress && progressEvent.event.lengthComputable) {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        setProgress(percentCompleted);
      }
    },
  });

  return sendRequest(postPromise);
}

export {
  fetchInboundList,
  fetchInboundTypeTransactionDetail,
  fetchInbound,
  updateInbound,
  sapImport,
  createInbound,
};
