import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Button, Column, Table } from 'views/components/elements';
import { Box, IconButton, Tooltip, Typography } from '@mui/material';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import Tab from '@mui/material/Tab';
import {
  Actions,
  CustomTabs,
  RubikTextMedium,
  RubikTextRegular,
} from '../../../views/pages/delivery/StyledComponent';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import LogHistory from './log-history';
import PopUpDialog from 'views/components/elements/PopUpDialog';
import InboundForm from './inbound-edit';
import { useAlertStore } from 'store/alert';
import { fetchInbound } from 'gateways/inbound';
import { handleError } from 'views/shared/utils/error-utils';
import { Inbound, InboundLine } from 'models';
import { dateTimeFormat } from 'views/shared/utils/string-utils';
import styled from '@emotion/styled';
import { mediaQuery } from 'theme/viewport';

type ViewInboundProps = {
  isOpen: boolean;
  onClose: () => void;
  inboundId: string;
};

const Grid2Column = styled(Box)({
  display: 'grid',
  gridTemplateColumns: '0.35fr auto',
  gap: '2ch',
  alignItems: 'center',
});

const GridRow = ({ field, value }: { field: string; value: string }) => {
  return (
    <>
      <RubikTextMedium>{field}</RubikTextMedium>
      {value?.length <= 40 ? (
        <RubikTextRegular>{value}</RubikTextRegular>
      ) : (
        <Tooltip title={value} placement='bottom-start'>
          <RubikTextRegular className='truncate' sx={{ maxWidth: '40ch' }}>
            {value}
          </RubikTextRegular>
        </Tooltip>
      )}
    </>
  );
};

export default function ViewInbound({
  isOpen,
  onClose,
  inboundId,
}: ViewInboundProps) {
  const [activeTab, setActiveTab] = useState(0);
  const { setAlert } = useAlertStore();

  const [isEditOpen, setIsEditOpen] = useState(false);
  const [isLogHistoryOpen, setIsLogHistoryOpen] = useState(false);
  const [inboundData, setInboundData] = useState<Inbound>(null);

  const handleLogHistoryOpen = () => setIsLogHistoryOpen(true);
  const handleLogHistoryClose = () => setIsLogHistoryOpen(false);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  const fetchInboundById = async (inboundId: string) => {
    const response = await fetchInbound(inboundId);

    const { failed, error, data } = handleError<Inbound>(response);

    if (failed) {
      return setAlert(error);
    }

    setInboundData(data);
  };

  useEffect(() => {
    fetchInboundById(inboundId);
  }, []);

  if (!inboundData) {
    return null;
  }

  const columns: Column<InboundLine>[] = [
    {
      field: 'line_number',
      label: 'Line Number',
      width: 120,
    },
    {
      field: 'batch_number',
      label: 'Batch Number',
      width: 140,
    },
    {
      field: 'po_number',
      label: 'PO Number',
      width: 140,
    },
    {
      field: 'quantity_qa',
      label: 'Quantity QA',
    },
    {
      field: 'quantity_qa',
      label: 'QTY QA',
    },
    {
      field: 'quantity_case',
      label: 'QTY CASE',
    },
    {
      field: 'quantity_expected',
      label: 'QTY EXPECTED',
    },
    {
      field: 'quantity_accepted',
      label: 'QTY ACCEPTED',
    },
    {
      field: 'quantity_rejected',
      label: 'QTY REJECTED',
    },
  ];

  const handleEditFormPopUp = (isOpen: boolean) => () => setIsEditOpen(isOpen);

  // Handle the data when doing the integration
  const handleEditInboundSubmit = (failed: boolean, error: string) => {
    if (failed) {
      return setAlert(error);
    }

    handleEditFormPopUp(false)();
    fetchInboundById(inboundId);
  };

  const {
    created_date_time,
    inbound_number,
    inbound_status,
    lines,
    supplier,
    comments,
    description,
    gr_number,
    inbound_end_time,
    inbound_start_time,
    inbound_type,
    order_number,
    shipping_reference,
    source_location,
    source_warehouse,
    warehouse,
    updated_date_time,
    vehicle_number,
    transporter,
  } = inboundData;

  const inboundStartTime = inbound_start_time
    ? (dateTimeFormat(inbound_start_time, 'datetime') as string)
    : '-';
  const inboundEndTime = inbound_end_time
    ? (dateTimeFormat(inbound_end_time, 'datetime') as string)
    : '-';
  const createdDateTime = created_date_time
    ? (dateTimeFormat(created_date_time, 'datetime') as string)
    : '-';
  const updatedDateTime = updated_date_time
    ? (dateTimeFormat(updated_date_time, 'datetime') as string)
    : '-';

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      fullScreen
      aria-labelledby='log-history-dialog-title'
      aria-describedby='log-history-dialog-description'
    >
      <DialogTitle
        id='log-history-dialog-title'
        style={{ padding: '16px 24px' }}
      >
        <Box style={{ display: 'flex', alignItems: 'center' }}>
          <Typography
            variant='h2'
            style={{ flex: 1, fontSize: '20px', fontWeight: 'bold' }}
          >
            View Inbound {inbound_number}
          </Typography>
          <DialogActions>
            <CancelRoundedIcon
              onClick={onClose}
              sx={{
                width: 32,
                height: 32,
                cursor: 'pointer',
                color: '#3460DC',
                [mediaQuery.down('lg')]: {
                  width: 24,
                  height: 24,
                },
              }}
            />
          </DialogActions>
        </Box>
      </DialogTitle>

      <DialogContent style={{ padding: '16px 24px' }}>
        {/* Order Details Section */}
        <Box style={{ display: 'flex', gap: '40px', marginBottom: '16px' }}>
          <span style={{ display: 'inline-flex', alignItems: 'center' }}>
            <RubikTextMedium>Order Number</RubikTextMedium>
            <RubikTextRegular style={{ marginLeft: '10px' }}>
              {order_number}
            </RubikTextRegular>
          </span>
          <span style={{ display: 'inline-flex', alignItems: 'center' }}>
            <RubikTextMedium>Supplier</RubikTextMedium>
            <RubikTextRegular style={{ marginLeft: '10px' }}>
              {supplier?.name}
            </RubikTextRegular>
          </span>
          <span style={{ display: 'inline-flex', alignItems: 'center' }}>
            <RubikTextMedium>Status</RubikTextMedium>
            <RubikTextRegular style={{ marginLeft: '10px' }}>
              {inbound_status}
            </RubikTextRegular>
          </span>
          <span style={{ display: 'inline-flex', alignItems: 'center' }}>
            <RubikTextMedium>Type</RubikTextMedium>
            <RubikTextRegular style={{ marginLeft: '10px' }}>
              {inbound_type}
            </RubikTextRegular>
          </span>
        </Box>

        {/* Tabs Section */}
        <CustomTabs
          value={activeTab}
          onChange={handleTabChange}
          variant='scrollable'
          scrollButtons='auto'
          aria-label='log-history-tabs'
          style={{ marginBottom: '16px' }}
        >
          <Tab label='Lines' sx={{ textTransform: 'none' }} />
          <Tab label='More Details' sx={{ textTransform: 'none' }} />
          {activeTab === 1 && (
            <div
              style={{
                alignSelf: 'center',
                background: '#E7ECF3',
                borderRadius: '5px',
                marginLeft: '16px',
              }}
            >
              <IconButton
                size='small'
                aria-label='edit-more-details'
                onClick={handleEditFormPopUp(true)}
              >
                <EditRoundedIcon
                  sx={{
                    fontSize: 20,
                    color: '#3460DC',
                    [mediaQuery.down('lg')]: { fontSize: '14px' },
                  }}
                />
              </IconButton>
            </div>
          )}
        </CustomTabs>

        {/* Tab Content */}
        {activeTab === 0 && (
          <>
            <Box
              className='custom-table-wrapper'
              style={{ marginTop: '16px', height: '35%' }}
            >
              <Table columns={columns} rows={lines} stickyHeader />
            </Box>
            <Actions className='btn-container'>
              <Button variant='text' color='secondary' className='cancel-btn'>
                Back
              </Button>
              <Button type='submit' onClick={handleLogHistoryOpen}>
                Log History
              </Button>
            </Actions>
          </>
        )}

        {activeTab === 1 && (
          <Box>
            <Box style={{ display: 'flex', gap: '40px', padding: '16px' }}>
              {/* Left Side Fields */}
              <Grid2Column style={{ flex: 1 }}>
                <GridRow
                  field='Warehouse'
                  value={warehouse.warehouse_name || '-'}
                />
                <GridRow
                  field='Shipping Reference'
                  value={shipping_reference || '-'}
                />
                <GridRow field='Truck Number' value={vehicle_number || '-'} />
                <GridRow field='Transporter' value={transporter || '-'} />
                <GridRow field='GR Number' value={gr_number || '-'} />
                <GridRow
                  field='Source Warehouse'
                  value={source_warehouse || '-'}
                />
                <GridRow
                  field='Source Location'
                  value={source_location || '-'}
                />
              </Grid2Column>

              {/* Right Side Fields */}
              <Grid2Column style={{ flex: 1 }}>
                <GridRow field='Destination Location' value='-' />
                <GridRow field='Inbound Start Time' value={inboundStartTime} />
                <GridRow field='Inbound End Time' value={inboundEndTime} />
                <GridRow field='Comments' value={comments || '-'} />
                <GridRow field='Description' value={description || '-'} />
                <GridRow field='Created Date Time' value={createdDateTime} />
                <GridRow field='Updated Date Time' value={updatedDateTime} />
              </Grid2Column>
            </Box>
            <Box>
              <Actions className='btn-container'>
                <Button variant='text' color='secondary' className='cancel-btn'>
                  Back
                </Button>
                <Button type='submit' onClick={handleLogHistoryOpen}>
                  Log History
                </Button>
              </Actions>
            </Box>
          </Box>
        )}
        {isEditOpen && (
          <PopUpDialog
            open={isEditOpen}
            onClose={() => setIsEditOpen(false)}
            title={`Edit Inbound ${inbound_number}`}
            sx={{ maxWidth: '880px', maxHeight: 'max-content' }}
          >
            <InboundForm
              value={inboundData}
              onSubmit={handleEditInboundSubmit}
              onClose={handleEditFormPopUp(false)}
            />
          </PopUpDialog>
        )}
        {isLogHistoryOpen && (
          <LogHistory
            isOpen={isLogHistoryOpen}
            onClose={handleLogHistoryClose}
          />
        )}
      </DialogContent>
    </Dialog>
  );
}
