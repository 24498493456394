import {
  fetchInboundList,
  fetchInboundTypeTransactionDetail,
} from 'gateways/inbound';
import { ApiParams } from 'gateways/warehouse';
import { AggregatedInboundData, Inbound } from 'models';
import { handleError } from 'views/shared/utils/error-utils';
import { removeEmptyValues } from 'views/shared/utils/string-utils';
import { create } from 'zustand';

type Action = {
  fetchInboundsPaginated: (params?: ApiParams) => Promise<void>;
  fetchInboundTransactionDetail: (
    fromDate: string,
    toDate: string
  ) => Promise<void>;
  resetInboundData: () => void;
  setError?: (error: any) => void;
};

type State = {
  inbounds: Inbound[];
  hasNextPage: boolean;
  filter: ApiParams;
  fetching?: boolean;
  error?: any;
  aggregatedData?: AggregatedInboundData;
};

const defaultAggregatedData: AggregatedInboundData = {
  supplier: 0,
  transfer: 0,
  return: 0,
};

const useInboundStore = create<State & Action>()((set, get) => ({
  inbounds: [],
  hasNextPage: false,
  filter: {},
  error: null,
  fetching: false,
  selectedInbound: null,
  aggregatedData: defaultAggregatedData,

  fetchInboundsPaginated: async (params?: ApiParams): Promise<void> => {
    if (get().fetching) {
      return;
    }
    const apiParams = removeEmptyValues(params) ?? {};

    apiParams.limit ??= 10;
    apiParams.page ??= 1;

    set(() => ({
      fetching: true,
    }));

    try {
      const apiResponse = await fetchInboundList(apiParams);

      set(state => ({
        filter: apiParams,
        inbounds:
          apiParams.page === 1
            ? apiResponse.collection
            : state.inbounds.concat(apiResponse.collection),
        hasNextPage:
          (apiParams.page as number) < apiResponse.metadata.last_page,
      }));
    } catch (err) {
      set(() => ({
        error: 'Failed to fetch Inbounds',
      }));
    }
    set(() => ({
      fetching: false,
    }));
  },

  fetchInboundTransactionDetail: async (fromDate: string, toDate: string) => {
    try {
      const apiResponse = await fetchInboundTypeTransactionDetail({
        fromDate,
        toDate,
      });
      const { data, failed } = handleError<AggregatedInboundData>(apiResponse);

      if (failed) {
        throw new Error();
      }
      set(() => ({
        aggregatedData: {
          supplier: data.supplier ?? 0,
          transfer: data.transfer ?? 0,
          return: data.return ?? 0,
        },
      }));
    } catch (err) {
      set(() => ({
        error: 'Failed to fetch inbound transaction detail.',
      }));
    }
  },

  resetInboundData: () => {
    set(() => ({
      inbounds: [],
      hasNextPage: false,
      filter: {},
      error: null,
      fetching: false,
      selectedInbound: null,
      aggregatedData: defaultAggregatedData,
    }));
  },

  setError: (error: any) => {
    set(() => ({
      error,
    }));
  },
}));

export { useInboundStore };
