import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';

interface AlertDialogProps {
  open: boolean;
  title: string;
  message: string;
  onCancel: () => void;
  onDiscard: () => void;
}

const AlertDialog: React.FC<AlertDialogProps> = ({ open, title, message, onCancel, onDiscard }) => {
  return (
    <Dialog
      open={open}
      onClose={onCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" sx={{ fontWeight: 600 }}>
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description" sx={{ fontSize: '16px', color: 'black' }}>
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'flex-end', pr: 5, fontSize: '16px' }}>
        <Button
          onClick={onCancel}
          variant='contained'
          color='primary'
          sx={{ textTransform: 'none'}}
        >
          Cancel
        </Button>
        <Button
          onClick={onDiscard}
          variant="text"
          color="secondary"
          className="cancel-btn"
          sx={{ textTransform: 'none', color: '#323130', border: '1px solid #8A8886', marginLeft: 2.5 }}>
          Discard
        </Button>
      </DialogActions>
      <br></br>
    </Dialog>
  );
};

export default AlertDialog;
