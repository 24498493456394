import { Button, Input, Textarea } from 'views/components/elements';
import {
  FormField,
  Required,
  Actions,
  Content,
  PopUpForm,
} from 'views/pages/delivery/StyledComponent';
import {
  removeExtraSpace,
  inboundValidationSchema,
} from 'views/shared/utils/form-validator';
import { InboundFormData } from 'models/Form';
import { Inbound } from 'models';
import { ErrorMessage } from 'formik';
import { updateInbound } from 'gateways/inbound';
import { handleError } from 'views/shared/utils/error-utils';
import { Box } from '@mui/material';
import styled from '@emotion/styled';
import { mediaQuery } from 'theme/viewport';

interface InboundFormProps {
  value: Partial<Inbound>;
  onSubmit: (failed: boolean, error: string, data: Partial<Inbound>) => void;
  onClose: () => void;
}

const StyledFormField = styled(FormField)({
  marginBottom: 18,
  [mediaQuery.down('lg')]: {
    marginBottom: 14,
  },
});

const StyledTextArea = styled(Textarea)({
  height: 100,
  [mediaQuery.down('lg')]: {
    fontSize: 11,
    height: 70,
  },
});

const InboundForm: React.FC<InboundFormProps> = ({
  value,
  onSubmit,
  onClose,
}: InboundFormProps) => {
  const handleSubmit = async ({
    order_number,
    vehicle_number,
    shipping_reference,
    transporter,
    gr_number,
    comments,
    description,
  }: InboundFormData) => {
    const input = {
      ...value,
      order_number: removeExtraSpace(order_number),
      vehicle_number: removeExtraSpace(vehicle_number),
      shipping_reference: removeExtraSpace(shipping_reference),
      transporter: removeExtraSpace(transporter) || null,
      gr_number: removeExtraSpace(gr_number) || null,
      comments: removeExtraSpace(comments),
      description: removeExtraSpace(description) || null,
    } as Inbound;

    const result = await updateInbound(input);
    const { failed, error, data } = handleError<Inbound>(result);

    onSubmit(failed, error, data);
  };

  return (
    <PopUpForm
      className='user-form form'
      initialValues={value}
      validationSchema={inboundValidationSchema}
      validateOnChange={true}
      validateOnBlur={true}
      onSubmit={handleSubmit}
      onReset={onClose}
    >
      {({ errors, touched, setFieldTouched, setFieldValue }) => (
        <Content>
          <Box className='form-container' style={{ display: 'flex' }}>
            <Box style={{ width: '50%', paddingRight: '1rem' }}>
              <StyledFormField className='form-group'>
                <label>
                  Order Number<Required>*</Required>
                </label>
                <Input
                  name='order_number'
                  placeholder='Order Number'
                  className={
                    touched.order_number && errors.order_number
                      ? 'error-input'
                      : ''
                  }
                  onChange={event => {
                    const { name, value } = event.target;
                    setFieldTouched(name, true);
                    setFieldValue(name, value);
                  }}
                />
                <ErrorMessage
                  name='order_number'
                  component='span'
                  className='error'
                />
              </StyledFormField>
              <StyledFormField className='form-group'>
                <label>
                  Shipping Reference <Required>*</Required>
                </label>
                <Input
                  name='shipping_reference'
                  placeholder='Shipping Reference'
                  className={
                    touched.shipping_reference && errors.shipping_reference
                      ? 'error-input'
                      : ''
                  }
                  onChange={event => {
                    const { name, value } = event.target;
                    setFieldTouched(name, true);
                    setFieldValue(name, value);
                  }}
                />
                <ErrorMessage
                  name='shipping_reference'
                  component='span'
                  className='error'
                />
              </StyledFormField>
              <StyledFormField className='form-group'>
                <label>GRN Number</label>
                <Input
                  name='gr_number'
                  placeholder='GRN Number'
                  className={
                    touched.gr_number && errors.gr_number ? 'error-input' : ''
                  }
                  onChange={event => {
                    const { name, value } = event.target;
                    setFieldTouched(name, true);
                    setFieldValue(name, value);
                  }}
                />
                <ErrorMessage
                  name='gr_number'
                  component='span'
                  className='error'
                />
              </StyledFormField>
            </Box>
            <Box style={{ width: '50%', paddingLeft: '1rem' }}>
              <StyledFormField className='form-group'>
                <label>
                  Vehicle Number <Required>*</Required>
                </label>
                <Input
                  name='vehicle_number'
                  placeholder='Vehicle Number'
                  className={
                    touched.vehicle_number && errors.vehicle_number
                      ? 'error-input'
                      : ''
                  }
                  onChange={event => {
                    const { name, value } = event.target;
                    setFieldTouched(name, true);
                    setFieldValue(name, value);
                  }}
                />
                <ErrorMessage
                  name='vehicle_number'
                  component='span'
                  className='error'
                />
              </StyledFormField>
              <StyledFormField className='form-group'>
                <label>Transporter</label>
                <Input
                  name='transporter'
                  placeholder='Transporter'
                  className={
                    touched.transporter && errors.transporter
                      ? 'error-input'
                      : ''
                  }
                  onChange={event => {
                    const { name, value } = event.target;
                    setFieldTouched(name, true);
                    setFieldValue(name, value);
                  }}
                />
                <ErrorMessage
                  name='transporter'
                  component='span'
                  className='error'
                />
              </StyledFormField>
              <StyledFormField className='form-group'>
                <label>
                  Comments <Required>*</Required>
                </label>
                <Input
                  name='comments'
                  placeholder='Comments'
                  className={
                    touched.comments && errors.comments ? 'error-input' : ''
                  }
                  onChange={event => {
                    const { name, value } = event.target;
                    setFieldTouched(name, true);
                    setFieldValue(name, value);
                  }}
                />
                <ErrorMessage
                  name='comments'
                  component='span'
                  className='error'
                />
              </StyledFormField>
            </Box>
          </Box>
          <Box>
            <FormField className='form-group'>
              <label className='labelStyles'>Description</label>
              <StyledTextArea
                name='description'
                placeholder='Description'
                rows={4}
                className={
                  touched.description && errors.description ? 'error-input' : ''
                }
                onChange={event => {
                  const { name, value } = event.currentTarget;
                  setFieldTouched(name, true);
                  setFieldValue(name, value);
                }}
              />
              <ErrorMessage
                name='description'
                component='span'
                className='error'
              />
            </FormField>
          </Box>

          <Actions className='btn-container flex-justify-end'>
            <Button type='submit'>{'Save'}</Button>
            <Button
              variant='text'
              color='secondary'
              className='cancel-btn'
              onClick={onClose}
            >
              Discard
            </Button>
          </Actions>
        </Content>
      )}
    </PopUpForm>
  );
};

export default InboundForm;
