import moment, { Moment } from 'moment';
import { CustomDateRangeOptions } from 'views/pages/delivery/dashboard/reusables';

interface FormatNumberOptions extends Intl.NumberFormatOptions {
  locals?: string | string[];
}

interface FormatDateOptions extends Omit<Intl.DateTimeFormat, 'format' | 'resolvedOptions' | 'formatToParts' | 'formatRange' | 'formatRangeToParts'> {
  locals?: string | string[];
  format?: Intl.DateTimeFormat['format'];
  resolvedOptions?: Intl.DateTimeFormat['resolvedOptions'];
  formatToParts?: Intl.DateTimeFormat['formatToParts'];
  formatRange?: Intl.DateTimeFormat['formatRange'];
  formatRangeToParts?: Intl.DateTimeFormat['formatRangeToParts'];
}

export type DateRangeOptions = 'today' | 'last-7-days' | 'last-3-months' | 'last-6-months';
export type MomentDateRangeResult = {
  from: Moment;
  to: Moment;
};

export interface DateFilter {
  label: CustomDateRangeOptions;
  from?: string | Date;
  to?: string | Date;
}

export const formatNumberToCurrencyAmount = (value: number, options: FormatNumberOptions = {}) => {
  if (!value) {
    value = 0;
  }

  const { locals, ...rest } = options;
  return new Intl.NumberFormat(locals || 'en-IN', {
    style: 'currency',
    currency: 'INR',
    minimumFractionDigits: 2,
    ...rest,
  }).format(value);
};

export const formatTimestampToDate = (value: Date | number, options: FormatDateOptions = {}) => {
  if (!value) {
    return '';
  }
  value = new Date(value);
  const { locals, ...rest } = options;
  const formattedDate = new Intl.DateTimeFormat(locals || 'en-IN', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    ...rest,
  }).format(value).split(' ');
  return `${formattedDate[0]} ${formattedDate[1]}, ${formattedDate[2]}`;
};

export const getLastMonthDate = () => dateTimeFormat(moment().subtract(1, 'months').toDate(), 'yyyy-MM-DD');

const currencyFormatter = Intl.NumberFormat('en-IN', {
  style: 'currency',
  currency: 'INR'
});

const currencyFormat = (value: number) => {
  if (!value) {
    return value;
  }

  return currencyFormatter.format(value).replace(/₹/, '₹ ');
};

const dateTimeFormat = (value: Date | string | number, format: 'date' | 'time' | 'datetime' | 'timestamp' | 'yyyy-MM-DD') => {
  if (!value) {
    return value;
  }

  if (format === 'date') {
    return moment(value).format('MMM DD, yyyy');
  }

  if (format === 'datetime') {
    return moment(value).format('MMM DD, yyyy hh:mm A');
  }

  if (format === 'time') {
    return moment(value).format('hh:mm A');
  }

  if (format === 'timestamp') {
    return moment(value).format('DD-MM-YYYY HH:mm:ss'); 
  }

  if (format === 'yyyy-MM-DD') {
    return moment(value).format(format);
  }
};

const getTodayDate = () => {
  const today = new Date();
  const dd = String(today.getDate()).padStart(2, '0');
  const mm = String(today.getMonth() + 1).padStart(2, '0'); 
  const yyyy = today.getFullYear();
  return `${yyyy}-${mm}-${dd}`;
};

const getAvatarText = (value: string) => value.match(/^\s*(\w)|\b(\w)(?=\S+$)/g)?.join('');

export { currencyFormat, dateTimeFormat, getAvatarText, getTodayDate };

export const downloadBase64String = (string, filename = 'download') => {
  const link = document.createElement('a');
  link.href = string;
  link.download = filename;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const calculateDateRange = (datePickerType: DateRangeOptions): MomentDateRangeResult => {
  const today = moment();

  switch(datePickerType) {
  case 'today':
    return {from: today, to: today};
  case 'last-7-days':
    return {from: moment().subtract(7, 'days'), to: today};
  case 'last-3-months':
    return { from: moment().subtract(3, 'months'), to: today};
  case 'last-6-months':
    return {from: moment().subtract(6, 'months'), to: today};
  }
};

export const getFormattedDate = (dateFormat: string, {from, to, label}: DateFilter): {from: string, to: string} => {
  let formattedFromDate = moment(from).format(dateFormat);
  let formattedToDate = moment(to).format(dateFormat);

  if (label !== 'custom-date' && label !== 'view-all') {
    const temp = calculateDateRange(label);
    formattedFromDate = temp.from.format(dateFormat);
    formattedToDate = temp.to.format(dateFormat);
  }

  if (label === 'view-all') {
    formattedFromDate = undefined;
    formattedToDate = undefined;
  }

  return {from: formattedFromDate, to: formattedToDate};
};

export const removeEmptyValues = (obj) => {
  if (!obj) {
    return undefined;
  }
  return Object.fromEntries(
    Object.entries(obj).filter(([,value]) => value !== null && value !== '')
  );
};
