import OutlinedInput, { OutlinedInputProps } from '@mui/material/OutlinedInput';
import { styled } from '@mui/material/styles';
import { Field } from 'formik';
import { mediaQuery } from 'theme/viewport';

const StyledInput = styled(OutlinedInput)(() => ({
  ['& .MuiInputBase-input']: {
    padding: '9.5px 12px',
    height: 'unset',
  },
  fontSize: '14px',
  lineHeight: 'normal',
  [mediaQuery.down('lg')]: {
    padding: '4px 6px',
    fontSize: 10,

    ['& .MuiInputBase-input']: {
      padding: '4px 6px',
      height: 'unset',
    },
    ['& .MuiSvgIcon-root']: {
      fontSize: 16,
    },
  },
}));

export default function Input(props: OutlinedInputProps) {
  const { name, ...rest } = props;

  return (
    <Field name={name}>
      {({ field }) => <StyledInput {...field} {...rest} />}
    </Field>
  );
}
