import { DashBoardField, TextDashBoardField } from './dashboard-field';
import { DashBoardListTag, DashBoardProps, TextDashBoardProps } from './reusables';

const DashBoardList: React.FC<DashBoardProps> = ({
  data,
  selectedField,
  onChange,
  children,
}) => {

  return (
    <DashBoardListTag>
      {children}
      {Object.entries(data).map(([key, value]) => {
        return (
          <DashBoardField
            key={key}
            field={key as any}
            value={value}
            selected={selectedField === key}
            onClick={() => onChange(key)}
          />
        );
      })}
    </DashBoardListTag>
  );
};

const TextDashBoardList: React.FC<TextDashBoardProps> = ({
  data,
  selectedField,
  onChange,
  children,
}) => {

  return (
    <DashBoardListTag>
      {children}
      {data.map((key) => {
        return (
          <TextDashBoardField
            key={key}
            field={key as any}
            selected={selectedField === key}
            onClick={() => onChange(key)}
          />
        );
      })}
    </DashBoardListTag>
  );
};

export { DashBoardList, TextDashBoardList };
