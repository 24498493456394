import { ChangeEvent, SyntheticEvent, useState } from 'react';
import { SearchableDropDown, SearchableDropDownProps } from 'views/components/dropdown';
import { UOM } from 'models';
import { fetchUOMList } from 'gateways/product';
import { handleError } from 'views/shared/utils/error-utils';

interface UOMSearchableDropDownProps {
  required?: boolean;
  onSelect: (uom: UOM) => void;
  disabled?: boolean;
  value?: UOM,
}

export function UOMSearchableDropDown({
  required,
  onSelect,
  disabled,
  value,
}: UOMSearchableDropDownProps) {
  const [options, setOptions] = useState<UOM[]>([]);

  const handleSearch = async (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value: query } = event.target;
    if (!query?.trim()) {
      return setOptions([]);
    }

    const apiResponse = await fetchUOMList(query);
    const { failed, data } = handleError<UOM[]>(apiResponse);

    setOptions(failed ? []: [...data]);
  };

  const onChange = (_: SyntheticEvent, newValue: UOM | null, reason?: string) => {
    if (reason === 'clear') {
      setOptions([]);
      onSelect(null);
      return;
    }

    if (newValue) {
      setOptions([newValue]);
      onSelect(newValue);
    }
  };
  
  const props: SearchableDropDownProps<UOM> = {
    value,
    required: Boolean(required),
    handleSearch,
    onChange,
    options,
    label: 'UOM',
    debounce: 400,
    isOptionEqualToValue: (option, value) =>
      option.uom_id === value.uom_id,
    getOptionLabel: option =>
      `${option.uom_code} - ${option.uom_name}`,
    onBlur: () => {
      setOptions(value ? [value]: []);
    },
    disabled,
  };

  return <SearchableDropDown  {...props}/>;
}
