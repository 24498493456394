import { ApiResponse, Product, ProductGroup, UOM } from 'models';
import { sendRequest, getRequest, putRequest } from './gateway';
import { ApiParams } from './warehouse';

function fetchUOMList(query: string): Promise<UOM> {
  const fetchPromise = getRequest<UOM>(`/v1/uom/list?query=${query}`);

  return sendRequest(fetchPromise);
}

function fetchProductGroupList(apiParams: ApiParams) {
  const fetchPromise = getRequest<ApiResponse<ProductGroup[]>>('/v1/productgroup/list', {
    data: {
      ...apiParams
    }
  });

  return sendRequest(fetchPromise);
}

function updateProduct({product_id, ...rest}: Product) {
  const fetchPromise = putRequest(`/v1/product/${product_id}`, {
    ...rest,
  });

  return sendRequest(fetchPromise);
}

function fetchUomById(uomId: number) {
  const fetchPromise = getRequest<ApiResponse<UOM>>(`/v1/uom/${uomId}`);
  return sendRequest(fetchPromise);
}

function fetchProductGroupById(productGroupId: string) {
  const fetchPromise = getRequest<ApiResponse<ProductGroup>>(`/v1/productgroup/${productGroupId}`);
  return sendRequest(fetchPromise);
}

export {
  fetchUOMList,
  fetchProductGroupList,
  updateProduct,
  fetchUomById,
  fetchProductGroupById,
};
