import { useState } from 'react';
import { Form, StepWizardProps } from 'views/components/elements';
import { styled, Box, Typography, Button, Link } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import { UploadedIcon } from 'views/components/icon';
import { useAlertStore } from 'store/alert';

const PopUpForm = styled(Form)({
  width: '100%',
  boxSizing: 'border-box',
  maxHeight: '100%',
  overflowY: 'auto',
});
const Content = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  rowGap: 12,
  border: '2px dashed #95ADEC',
  padding: '20px',
  borderRadius: '8px',
  textAlign: 'center',
  backgroundColor: '#f5f6f8c7',
  maxHeight: '300px',
  overflowY: 'auto',
  width: '100%',
});

interface ImportFileProps extends StepWizardProps {
  onFileImport: (fileName: File) => void;
}

const ImportFile: React.FC<ImportFileProps> = props => {
  // step wizard wrapper's children will be receiving these props by default
  const { goToNamedStep, onFileImport } = props;
  const { setAlert } = useAlertStore();

  const [file, setFile] = useState<File | null>(null);

  const handleDrop = (acceptedFiles: File[]) => {
    const selectedFile = acceptedFiles[0];

    // Check for 10MB file size limit
    if (selectedFile.size > 10 * 1024 * 1024) {
      setAlert('File size exceeds 10MB limit');
    } else {
      setFile(selectedFile);
      onFileImport(selectedFile);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleDrop,
    accept: {
      'text/csv': ['.csv'],
      'application/vnd.ms-excel': ['.xls'],
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
    },
    onError() {
      setAlert('Something went wrong. Please try again.');
    },
  });

  const handleSubmit = () => {
    if (file) {
      goToNamedStep('upload');
    }
  };

  return (
    <PopUpForm
      className='user-form form'
      onSubmit={handleSubmit}
      initialValues={{}}
    >
      <Content {...getRootProps()}>
        <input {...getInputProps()} />
        <Box>
          <UploadedIcon />
        </Box>
        <Typography variant='body2' sx={{ fontWeight: 'bold' }}>
          Drag & drop your file here
        </Typography>
        <Typography variant='caption'>
          or{' '}
          <Link component='button' variant='caption' /*onClick={() => {}} */>
            browse file
          </Link>{' '}
          on your computer
        </Typography>
        <br />
        <Typography variant='caption' color='#838993'>
          File Type: XLS | Max file size 10MB
        </Typography>
        {file && (
          <Typography variant='caption' color='#4E545F'>
            {file.name}
          </Typography>
        )}
      </Content>
      <Box mt={3} display='flex' justifyContent='right'>
        <Button
          variant='contained'
          color='primary'
          size='small'
          disabled={!file}
          onClick={handleSubmit}
          sx={{ textTransform: 'none' }}
        >
          Next
        </Button>
      </Box>
    </PopUpForm>
  );
};

export default ImportFile;
