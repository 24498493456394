import React, { useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { ArrowDownIcon, ArrowUpIcon } from '../icon';

interface NestedNavProps {
    to?: string;
    label: string;
    icon: React.ReactNode;
    subRoutes?: (Omit<NestedNavProps, 'subRoutes'>)[];
}

const NavItem: React.FC<NestedNavProps> = ({
  to,
  label,
  icon,
  subRoutes
}) => {

  const location = useLocation();
  const isActive = (path?: string) => (path ? location.pathname.includes(path) : false);

  const isOpen = subRoutes?.some((child) => isActive(child.to)) || false;

  const [open, setOpen] = useState(false);

  const Component = subRoutes ? 'div' : NavLink;

  useEffect(() => {
    setOpen(isOpen);
  },[location.pathname, isOpen]);

  const handleToggle = (e: React.MouseEvent<HTMLDivElement | HTMLAnchorElement>) => {
    if (subRoutes) {
      e.preventDefault(); 
      setOpen((prev) => !prev);
    }
  };

  return (
    <div className='nav-item'>
      <Component
        to={to || '#'}
        className={`nav-link parent-nav ${subRoutes ? 'nav-dropdown-header' : ''}`}
        onClick={handleToggle}
      >
        {icon}
        <span>{label}</span>
        {subRoutes && (open ? <ArrowUpIcon /> : <ArrowDownIcon />)}
      </Component>
      {
        subRoutes && open && <div className='child-menu'>
          {
            subRoutes.map((child, index) => {
              return (
                <NavLink to={child.to || '#'} key={index} className='nav-link child-nav'>
                  {child.icon}
                  <span>{child.label}</span>
                </NavLink>
              );
            })
          }
        </div>
      }
    </div>
  );
};

export default NavItem;
