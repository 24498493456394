import { DialogContent, Tooltip } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import React from 'react';

const Head = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  paddingRight: 24,
});

interface PopUpDialogProps {
  onClose: () => void;
  title: string;
  open: boolean;
  children?: React.ReactNode | undefined;
  sx?: object; // Optional sx prop to allow custom styles
}

const PopUpDialog: React.FC<PopUpDialogProps> = ({
  title,
  open,
  onClose,
  children,
  sx = {}, // Default to an empty object if sx is not provided
}: PopUpDialogProps) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth='sm'
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: '15px',
          ...sx, // Allow user to pass custom styles that override defaults
        },
      }}
    >
      <Head>
        <DialogTitle fontSize={'24px'} fontWeight={600} textTransform='capitalize'>
          {title}
        </DialogTitle>
        <IconButton onClick={onClose}>
          <Tooltip title='close'>
            <CancelRoundedIcon
              sx={{
                width: 32,
                height: 32,
                cursor: 'pointer',
                color: '#3460DC',
              }}
            />
          </Tooltip>
        </IconButton>
      </Head>
      <DialogContent sx={{ overflow: 'hidden'}}>{children}</DialogContent>
    </Dialog>
  );
};

export default PopUpDialog;
