import { fetchSuppliersList } from 'gateways/warehouse';
import { ApiResponse } from 'models';
import { Supplier } from 'models/Supplier';
import { ChangeEvent, SyntheticEvent, useState } from 'react';
import { SearchableDropDown, SearchableDropDownProps } from 'views/components/dropdown';
import { handleError } from 'views/shared/utils/error-utils';

interface SupplierSearchableDropDownProps {
  required?: boolean;
  onSelect: (Supplier: Supplier) => void;
  disabled?: boolean;
  value?: Supplier,
}

export function SupplierSearchableDropDown({
  required,
  onSelect,
  disabled,
  value,
}: SupplierSearchableDropDownProps) {
  const [options, setOptions] = useState<Supplier[]>([]);

  const handleSearch = async (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value: query } = event.target;
    if (!query?.trim()) {
      return setOptions([]);
    }

    const apiResponse = await fetchSuppliersList({
      query,
      limit: 25,
    });

    const { failed, data } = handleError<ApiResponse<Supplier[]>>(apiResponse);

    setOptions(failed ? []: [...data.collection]);
  };

  const onChange = (_: SyntheticEvent, newValue: Supplier | null, reason?: string) => {
    if (reason === 'clear') {
      setOptions([]);
      onSelect(null);
      return;
    }

    if (newValue) {
      setOptions([newValue]);
      onSelect(newValue);
    }
  };
  
  const props: SearchableDropDownProps<Supplier> = {
    value,
    required: Boolean(required),
    handleSearch,
    onChange,
    options,
    label: 'Supplier',
    debounce: 400,
    isOptionEqualToValue: (option, value) =>
      option.supplier_id === value.supplier_id,
    getOptionLabel: option =>
      `${option.code} - ${option.name}`,
    onBlur: () => {
      setOptions(value ? [value]: []);
    },
    disabled,
  };

  return <SearchableDropDown  {...props}/>;
}
