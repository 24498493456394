import styled from '@emotion/styled';
import { ListItemText, Menu, MenuItem, SxProps } from '@mui/material';
import { PropsWithChildren, ReactNode, useEffect, useState } from 'react';
import { CheckMarkIcon } from 'views/components/icon';

interface Option {
  id: string;
  label: string;
}

interface CustomMenuProps extends PropsWithChildren {
  options: Option[];
  children: ReactNode;
  sx?: SxProps;
  defaultSelectedOptions?: string[];
  onSelectionChange?: (items: string[]) => void;
  checkmark?: boolean;
  checkMarkIcon?: React.ReactNode;
}

const TriggerEl = styled('span')();
const StyledListItemText = styled(ListItemText)({
  textTransform: 'capitalize',
  color: '#3460DC'
});
const StyledMenuItem = styled(MenuItem)({
  paddingTop: '0.5rem',
  paddingBottom: '0.5rem',
});

const CustomMenu: React.FC<CustomMenuProps> = ({
  options,
  children,
  sx = {},
  defaultSelectedOptions = [],
  onSelectionChange,
  checkmark = true,
  checkMarkIcon = <CheckMarkIcon />,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const open = Boolean(anchorEl);

  useEffect(() => {
    setSelectedOptions(defaultSelectedOptions);
  }, [defaultSelectedOptions]);

  const handleIconClick = (event: React.MouseEvent<HTMLElement>) =>
    setAnchorEl(event.currentTarget);

  const handleClose = () => {
    setAnchorEl(null);
    onSelectionChange(selectedOptions);
  };

  const handleToggle = (optionId: string) => {
    setSelectedOptions(prev =>
      prev.includes(optionId)
        ? prev.filter(id => id !== optionId)
        : [...prev, optionId]
    );
  };

  return (
    <div>
      <TriggerEl onClick={handleIconClick}>{children}</TriggerEl>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={sx}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        slotProps={{
          paper: {
            style: {
              width: '20ch',
            },
          },
        }}
      >
        {options.map(option => (
          <StyledMenuItem key={option.id} onClick={() => handleToggle(option.id)}>
            <StyledListItemText primary={option.label} />
            {checkmark && selectedOptions.includes(option.id) && checkMarkIcon}
          </StyledMenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default CustomMenu;
