import { create } from 'zustand';
import { handleError } from 'views/shared/utils/error-utils';
import { fetchConfigurationByName } from 'gateways/configuration';
import { BusinessConfiguration, VisibilityFlags } from 'models';

const CONFIGURATION_DEFAULTS: VisibilityFlags = {
  IsWarehouseMasterVisible: false,
  IsCustomerMasterVisible: true,
  IsDeliveriesVisible: true,
  IsInboundVisible: false,
  IsOutboundVisible: false,
  IsSupplierVisible: false,
};

type Action = {
  configurations: Partial<VisibilityFlags>;
};

type State = {
  fetchConfiguration: (configName: keyof VisibilityFlags) => Promise<void>;
};

const useConfigurationStore = create<State & Action>()((set) => ({
  configurations: {},
  fetchConfiguration: async (configName) => {
    const response = await fetchConfigurationByName(configName);
    const { failed, data } = handleError<BusinessConfiguration>(response);

    set((state) => ({
      configurations: {
        ...state.configurations,
        [configName]: failed ? CONFIGURATION_DEFAULTS[configName] : data.configuration_value,
      },
    }));
  },
}));

export { useConfigurationStore };
