import StepWizard from 'react-step-wizard';
import ImportFile from './import';
import ProductsNew from './products-new';
import AdditionalInformation from './additional-information';
import SummaryReport from './summary-report';
import PopUpDialog from 'views/components/elements/PopUpDialog';
import { useNavigate } from 'react-router-dom';
import './inbound-import.css';
import LoadImportFile from './load-import';
import { useState } from 'react';
import { CreateInboundResponseDto } from 'models';

const InboundImport: React.FC = () => {
  const navigate = useNavigate();
  const [title, setTitle] = useState('Inbound Import (SAP)');
  const [file, setFile] = useState<File | null>();
  const [data, setData] = useState<CreateInboundResponseDto>(null);
  const [ currentProductIndex, setCurrentProductIndex ] = useState(1);

  const handleClosePopUp = () => {
    navigate('/inbound', { replace: true });
  };
  const handleStepChange = (stats: any) => {
    // Update title based on the current step index
    switch (stats.activeStep) {
    case 1:
      setTitle('Inbound Import (SAP)');
      break;
    case 2:
      setTitle('Inbound Import (SAP)');
      break;
    case 3:
      setTitle('New Products Found!');
      break;
    case 4:
      setTitle('Additional Information');
      break;
    case 5:
      setTitle('Inbound Import Summary Report(SAP)');
      break;
    default:
      setTitle('Inbound Import (SAP)');
    }
  };

  const handleFileImport = (file: File) => setFile(file);
  const onSucessfulImport = (data: CreateInboundResponseDto) => setData(data);
  const onSubmit = () => setCurrentProductIndex((prev) => prev + 1);

  return (
    <PopUpDialog
      open
      onClose={handleClosePopUp}
      title={title}
      sx={{ maxWidth: 'max-content', maxHeight: 'max-content' }}
    >
      <StepWizard isLazyMount={true} onStepChange={handleStepChange}>
        <ImportFile onFileImport={handleFileImport} stepName='import-file' />
        <LoadImportFile
          file={file}
          onSuccess={onSucessfulImport}
          stepName='upload'
        />
        <ProductsNew stepName='new-products' count={data?.newProducts?.count} />
        <AdditionalInformation
          stepName='additional-info'
          currentProduct={data?.newProducts?.products[currentProductIndex - 1]}
          currentProductIndex={currentProductIndex}
          totalNewProducts={data?.newProducts?.count}
          onSubmit={onSubmit}
        />
        <SummaryReport
          stepName='summary-report'
          fileName={file?.name}
          inbound={data?.inbound}
        />
      </StepWizard>
    </PopUpDialog>
  );
};

export default InboundImport;
