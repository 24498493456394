import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import { Replay as RefreshIcon, Search as SearchIcon } from '@mui/icons-material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Action } from 'views/components/styled';
import { useSupplierStore } from 'store/supplier';
import { Button, Column, Form, Input, Table } from 'views/components/elements';
import { EditIcon } from 'views/components/icon';
import { CircularProgress, Tooltip } from '@mui/material';
import WarningCard from 'views/components/elements/WarningCard';
import SupplierAdd from './supplier-add';
import SupplierEdit from './supplier-edit';
import { Supplier } from 'models/Supplier';

function SupplierList() {
  const {
    suppliers,
    fetching,
    fetchSuppliersPaginated,
    hasNextPage,
    filter,
    error,
    setError
  } = useSupplierStore();
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const supplierId = queryParams.get('supplier_id');
  const isAdd = queryParams.get('add') === 'true';
  const query = queryParams.get('query') || '';
  const formRef = useRef(null);

  const [sortConfig, setSortConfig] = useState({
    field: '', order: null
  });

  const isResetDisabled = !query && !supplierId && !isAdd;
  // fetch data for the first time
  useEffect(() => {
    if (suppliers.length === 0) {
      fetchSuppliersPaginated();
    }
  }, []);

  // refresh after customer added / edited
  useEffect(() => {
    fetchSuppliersPaginated({ query }, true);
  }, [isAdd, supplierId, query]);

  const columns = [
    {
      field: 'code',
      label: 'SUPPLIER CODE',
      sortable: true,
      width: 180,
      autoResize: false,
    },
    {
      field: 'name',
      label: 'SUPPLIER NAME',
      valueGetter: ({ name }) => (
        <Tooltip title={name} placement='bottom-start'>
          <span>{name}</span>
        </Tooltip>
      ),
      width: 140,
      autoResize: false,
    },
    { field: 'phone', label: 'PHONE', width: 120, autoResize: false },
    { field: 'gst_number', label: 'GST Number', width: 120, autoResize: false },
    {
      field: 'address',
      label: 'ADDRESS',
      valueGetter: ({ address }) => (
        <Tooltip title={address} placement='bottom-start'>
          <span>{address}</span>
        </Tooltip>
      ),
      width: 220,
      autoResize: false,
    },
    {
      field: 'action',
      label: 'Action',
      valueGetter: row => (
        <IconButton onClick={() => handleEdit(row.supplier_id)}>
          <EditIcon />
        </IconButton>
      ),
      width: 80,
      autoResize: false,
    },
  ] as Column<Supplier>[];

  const handleAdd = () => {
    queryParams.set('add', 'true');
    navigate(`/settings/suppliers?${queryParams.toString()}`);
  };

  const handleEdit = (supplierId: string) => {
    queryParams.set('supplier_id', supplierId);
    navigate(`/settings/suppliers?${queryParams.toString()}`);
  };

  const handleSearch = (values: typeof filter) => {
    queryParams.set('query', values.query);
    queryParams.set('page', '1');
    navigate(`/settings/suppliers?${queryParams.toString()}`);
  };

  // Clear all query params and navigate to the default route
  const handleReset = () => {
    if (formRef.current) {
      formRef.current.resetForm({ values: { query: '' } }); 
    }
    navigate('/settings/suppliers'); // This will remove all query params
  };

  const handleNextPage = () => {
    const newFilter = Object.assign({}, filter, {
      page: filter.page + 1,
    });

    fetchSuppliersPaginated(newFilter);
  };

  const sortedSuppliers = [...suppliers].sort((a, b) => {
    if (a[sortConfig.field] < b[sortConfig.field]) return sortConfig.order === 'asc' ? -1 : 1;
    if (a[sortConfig.field] > b[sortConfig.field]) return sortConfig.order === 'asc' ? 1 : -1;
    return 0;
  });

  return (
    <div className='custom-table-wrapper'>
      <WarningCard
        open={error}
        onClose={() => setError(null)}
        message={error}
        severity='error'
        durationInSeconds={10}
      />
      <h1>Suppliers</h1>
      <Action>
        <Form
          innerRef={formRef} 
          initialValues={{ query }}
          onSubmit={handleSearch}
          validateOnChange={false}
        >
          <Input
            name='query'
            placeholder='Search Suppliers'
            startAdornment={<SearchIcon />}
          />
          <Button type='submit'>
            <SearchIcon />
          </Button>
          <Button
            variant='text'
            startIcon={
              <span id='package-icon'>
                <RefreshIcon />
              </span>
            }
            onClick={handleReset}
            disabled={isResetDisabled} 
          >
              Reset
          </Button>
        </Form>
        <div>
          <Button
            startIcon={<AddCircleOutlineIcon />}
            onClick={handleAdd}
          >
            Add Supplier
          </Button>
        </div>
      </Action>

      {fetching && (
        <div className='spinner'>
          <CircularProgress />
        </div>
      )}

      <Table
        rows={sortedSuppliers}
        columns={columns}
        stickyHeader
        infiniteScroll
        hasMoreRows={hasNextPage}
        sortingField={sortConfig.field}
        sortingOrder={sortConfig.order}
        onSortChange={(field, order) => setSortConfig({ field, order })}
        onNextPage={handleNextPage}
      />

      {!fetching && suppliers.length === 0 && (
        <div>No records found.</div>
      )}
      {isAdd && (
        <SupplierAdd/> 
      )}
      {supplierId && (
        <SupplierEdit supplierId={supplierId}/>
      )}
    </div>
  );
}

export default SupplierList;