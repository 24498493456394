import {
  ApiParams,
  createDelivery,
  fetchBusinessName,
  fetchDeliveries,
  fetchDelivery,
  fetchLRReceipt,
  fetchLogHistory,
  fetchShippingLabel,
  updateDelivery,
  updateDeliveryStatus,
  fetchWarehouses,
  createWarehouse,
  updateWarehouse,
} from 'gateways/warehouse';
import { Delivery, DeliveryListView, Warehouse } from 'models';
import { LogHistory } from 'models/LogHistory';
import {
  EntityResponse,
  getInitialDelivery,
  transformDelivery,
} from 'views/shared/utils/delivery-utils';
import { handleError } from 'views/shared/utils/error-utils';
import { create } from 'zustand';

type Action = {
  createDelivery: (delivery: Delivery) => Promise<EntityResponse<string>>;
  fetchDeliveries: (params: ApiParams) => Promise<EntityResponse<Delivery[]>>;
  fetchDelivery: (deliveryId: string) => Promise<void>;
  updateDelivery: (delivery: any) => Promise<EntityResponse<any>>;
  sortDeliveries: (field: string, order: string) => void;
  setDelivery: (delivery: Partial<Delivery>) => void;
  resetData: () => void;
  fetchShippingLabel: (deliveryId: string) => Promise<EntityResponse<string>>;
  fetchLRReceipt: (deliveryId: string) => Promise<EntityResponse<string>>;
  updateDeliveryStatus: (
    deliveryId: string,
    status: string
  ) => Promise<EntityResponse<string>>;
  fetchBusinessName: () => Promise<void>;
  fetchLogHistory: (params: ApiParams, deliveryId: string) => Promise<void>;
  fetchWarehouses: () => Promise<EntityResponse<Warehouse[]>>;
  createWarehouse: (warehouse: Warehouse) => Promise<EntityResponse<Warehouse>>;
  updateWarehouse: (warehouse: Warehouse) => Promise<EntityResponse<Warehouse>>;
  setSelectedWarehouse: (warehouse: Warehouse) => void;
};

interface DeliveryPageView {
  deliveryPageFilter: ApiParams;
  deliveryList: DeliveryListView[];
  hasNextPage: boolean;
  fetched: boolean;
}

const emptyDeliveryView = {
  deliveryPageFilter: {},
  deliveryList: [],
  hasNextPage: false,
  fetched: false,
} as DeliveryPageView;

type State = {
  hasNextPage: boolean,
  deliveryData: DeliveryPageView;
  delivery: Delivery;
  businessName: string;
  businessLogo: string;
  logHistory: LogHistory[];
  selectedWarehouse: Warehouse;
  warehouseList: Warehouse[];
};
const emptyData = [] as any;

const useWarehouseStore = create<State & Action>()((set, get) => ({
  hasNextPage: false,
  deliveryData: emptyDeliveryView,
  delivery: getInitialDelivery(),
  businessName: '',
  businessLogo: '',
  logHistory: emptyData,
  warehouseList: emptyData,
  selectedWarehouse: null,
  fetchDeliveries: async (params: ApiParams) => {
    params.limit ??= 10;
    params.page ??= 1;

    const response = await fetchDeliveries(params);

    const errorObj = handleError<Delivery[]>(response);
    if (errorObj?.failed) {
      return errorObj;
    }

    const collection = transformDelivery(
      Array.isArray(response?.collection) ? response?.collection : []
    );

    set(state => {
      const deliveryList =
        params.page === 1
          ? collection
          : state['deliveryData'].deliveryList.concat(collection);

      const data = {
        deliveryPageFilter: params,
        hasNextPage: params.page < response?.metadata?.last_page,
        deliveryList: deliveryList,
        fetched: true,
      };

      return { deliveryData: data };
    });
  },
  createDelivery: async (delivery: Delivery) => {
    const response = await createDelivery(delivery);

    return handleError(response);
  },
  sortDeliveries: (field: string, order: string) => {
    set(state => {
      const { deliveryData } = state;
      const sortedList = [...deliveryData.deliveryList].sort((a, b) => {
        if (a[field] < b[field]) return order === 'asc' ? -1 : 1;
        if (a[field] > b[field]) return order === 'asc' ? 1 : -1;
        return 0;
      });

      return { deliveryData: { ...deliveryData, deliveryList: sortedList } };
    });
  },
  fetchDelivery: async (deliveryId: string) => {
    const delivery = await fetchDelivery(deliveryId);

    const shipping_address =
      delivery?.delivery_location ?? delivery?.customer?.shipping_address;
    set(() => {
      return {
        delivery: delivery?.delivery_id
          ? { ...delivery, shipping_address }
          : getInitialDelivery(),
      };
    });
  },
  updateDelivery: async (delivery: Delivery) => {
    const response = await updateDelivery(delivery);

    return handleError<any>(response);
  },
  setDelivery: async (delivery: Partial<Delivery>) => {
    set(state => {
      const { delivery: prevDelivery } = state;
      const updatedDelivery = { ...prevDelivery, ...delivery };
      return { delivery: updatedDelivery };
    });
  },
  resetData: () => {
    set(() => {
      return {
        deliveryData: emptyDeliveryView,
        delivery: getInitialDelivery(),
      };
    });
  },
  fetchShippingLabel: async (deliveryId: string) => {
    const response = await fetchShippingLabel(deliveryId);
    return handleError<any>(response);
  },
  fetchLRReceipt: async (deliveryId: string) => {
    const response = await fetchLRReceipt(deliveryId);
    return handleError<any>(response);
  },
  updateDeliveryStatus: async (deliveryId: string, status: string) => {
    const response = await updateDeliveryStatus(deliveryId, status);
    return handleError(response);
  },
  fetchBusinessName: async () => {
    const response = await fetchBusinessName();
    const { business_id, name } = response.businessNameResponse.data;
    const businessDetails = Array.isArray(response.businessDetailResponse.data)
      ? response.businessDetailResponse.data
      : [];
  
    const businessDetail = businessDetails.find(
      (business: { business_id: string }) => business.business_id.toLocaleUpperCase() === business_id
    );
  
    const businessLogo = businessDetail ? businessDetail.logo_link : null;

    set(() => ({
      businessName: name,
      businessLogo,
    }));
  },  
  
  fetchLogHistory: async (params: ApiParams, delivery_id: string) => {
    params.limit ??= 10;
    params.page ??= 1;

    const page = await fetchLogHistory(params, delivery_id);

    set(state => ({
      filter: params,
      logHistory: params.page === 1 ? page.collection : state.logHistory.concat(page.collection),
      hasNextPage: params.page < page.metadata.last_page
    }));
  },
  fetchWarehouses: async (): Promise<EntityResponse<Warehouse[]>> => {
    const response = await fetchWarehouses();

    const errorObj = handleError<Warehouse[]>(response);
    if (errorObj?.failed) {
      return errorObj;
    }

    const warehouseList = [...errorObj.data];
    set(state => ({
      ...state,
      warehouseList,
      selectedWarehouse: warehouseList[0],
    }));
  },
  createWarehouse: async (
    warehouse: Warehouse
  ): Promise<EntityResponse<Warehouse>> => {
    const response = await createWarehouse(warehouse);

    const errorObj = handleError<Warehouse>(response);

    if (!errorObj.failed) {    
      set(state => {
        const { selectedWarehouse, warehouseList } = state;
        const newWarehouse = { ...errorObj.data };
        return {
          ...state,
          selectedWarehouse: warehouseList.length ? selectedWarehouse : newWarehouse,
          warehouseList: [...state.warehouseList, newWarehouse],
        };
      });
    }

    return errorObj;
  },
  updateWarehouse: async (
    warehouse: Warehouse
  ): Promise<EntityResponse<Warehouse>> => {
    const response = await updateWarehouse(warehouse);

    const errorObj = handleError<Warehouse>(response);

    if (!errorObj.failed) {
      const updatedWarehouseList = get().warehouseList.map(function (wr) {
        return this.warehouse_id === wr.warehouse_id ? this : wr;
      }, {...errorObj.data});

      set(state => ({
        ...state,
        selectedWarehouse: {...warehouse},
        warehouseList: [...updatedWarehouseList],
      }));
    }
    return errorObj;
  },
  setSelectedWarehouse: (warehouse: Warehouse) => {
    set(state => ({
      ...state,
      selectedWarehouse: { ...warehouse },
    }));
  },
}));

export { useWarehouseStore };
