import { ApiResponse, Outbound } from 'models';
import { sendRequest, getRequest } from './gateway';
import { ApiParams } from './warehouse';

function fetchOutboundList(params: ApiParams) {
  const fetchPromise = getRequest<ApiResponse<Outbound[]>>('/v1/outbound/list', {
    params,
  });

  return sendRequest(fetchPromise);
}

function fetchOutboundTypeTransactionDetail(params: ApiParams) {
  const fetchPromise = getRequest<ApiResponse<Outbound[]>>('/v1/outbound/transaction', {
    params,
  });

  return sendRequest(fetchPromise);
}

export { fetchOutboundList, fetchOutboundTypeTransactionDetail };
