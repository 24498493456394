import { useState } from 'react';
import { Box, DialogContent, Typography, Link } from '@mui/material';
import { RubikTextMedium, RubikTextRegular } from '../delivery/StyledComponent';
import ViewInbound from './view-inbound'; // Import the ViewInbound component
import { Inbound } from 'models';
import { dateTimeFormat } from 'views/shared/utils/string-utils';

interface SummaryReportProps {
  stepName: string;
  inbound: Inbound;
  fileName: string;
}

const SummaryReport: React.FC<SummaryReportProps> = ({ fileName, inbound }) => {
  const [isViewInboundOpen, setIsViewInboundOpen] = useState(false);

  const handleOpenViewInbound = () => setIsViewInboundOpen(true);
  const handleCloseViewInbound = () => setIsViewInboundOpen(false);

  if (!inbound) {
    return null;
  }

  const { inbound_number, order_number, supplier, inbound_type, created_date_time, source_location,  } = inbound;
  const createdDate = created_date_time ? dateTimeFormat(created_date_time, 'date') as string : '-';

  return (
    <DialogContent style={{ padding: '16px 1px' }}>
      {/* Order Details Section */}
      <Box style={{ display: 'flex', gap: '24px', marginBottom: '16px' }}>
        <span style={{ display: 'inline-flex', alignItems: 'center' }}>
          <RubikTextMedium>Inbound Number</RubikTextMedium>
          <RubikTextRegular style={{ marginLeft: '10px' }}>{inbound_number || '-'}</RubikTextRegular>
        </span>
        <span style={{ display: 'inline-flex', alignItems: 'center' }}>
          <RubikTextMedium>Order Number</RubikTextMedium>
          <RubikTextRegular style={{ marginLeft: '10px' }}>{ order_number || '-' }</RubikTextRegular>
        </span>
        <span style={{ display: 'inline-flex', alignItems: 'center' }}>
          <RubikTextMedium>Supplier</RubikTextMedium>
          <RubikTextRegular style={{ marginLeft: '10px' }}>{supplier?.name || '-'}</RubikTextRegular>
        </span>
        <span style={{ display: 'inline-flex', alignItems: 'center' }}>
          <RubikTextMedium>Type</RubikTextMedium>
          <RubikTextRegular style={{ marginLeft: '10px' }}>{inbound_type}</RubikTextRegular>
        </span>
        <span style={{ display: 'inline-flex', alignItems: 'center' }}>
          <RubikTextMedium>Created Date</RubikTextMedium>
          <RubikTextRegular style={{ marginLeft: '10px' }}>{createdDate}</RubikTextRegular>
        </span>
        <span style={{ display: 'inline-flex', alignItems: 'center' }}>
          <RubikTextMedium>Location</RubikTextMedium>
          <RubikTextRegular style={{ marginLeft: '10px' }}>
            {source_location || '-'}
          </RubikTextRegular>
        </span>
      </Box>
      <Box style={{ display: 'flex', gap: '24px', marginBottom: '16px' }}>
        <span style={{ display: 'inline-flex', alignItems: 'center' }}>
          <Box>
            Inbound Successfully Imported from {fileName}
          </Box>
        </span>
      </Box>
      <Typography variant='body2' style={{ marginTop: '8px' }}>
        <Link
          component='button'
          variant='body2'
          onClick={handleOpenViewInbound}
          style={{ cursor: 'pointer', color: '#3460DC' }}
        >
          View Inbound for more details
        </Link>
      </Typography>
      {isViewInboundOpen && (
        <ViewInbound
          inboundId={inbound.inbound_id}
          isOpen={isViewInboundOpen}
          onClose={handleCloseViewInbound}
        />
      )}
    </DialogContent>
  );
};

export default SummaryReport;
