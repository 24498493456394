import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Column, Table } from 'views/components/elements';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';

type LogHistoryProps = {
  isOpen: boolean;
  onClose: () => void;
};

export default function LogHistory({ isOpen, onClose }: LogHistoryProps) {
  // Static data for demonstration (replace with actual API fetches in production)
  const deliveryList = [
    {
      date_time: 'Jun 12, 2024, 07:45pm',
      name: 'Jane Smith',
      action: 'Update',
      change: '-',
    },
    {
      date_time: 'Jun 12, 2024, 07:45pm',
      name: 'Will Smith',
      action: 'Update',
      change: '-',
    },
    {
      date_time: 'Jun 12, 2024, 07:45pm',
      name: 'John Smith',
      action: 'Update',
      change: '-',
    },
    {
      date_time: 'Jun 12, 2024, 07:45pm',
      name: 'David',
      action: 'Update',
      change: '-',
    },
    {
      date_time: 'Jun 12, 2024, 07:45pm',
      name: 'Jane Smith',
      action: 'Update',
      change: '-',
    },
    {
      date_time: 'Jun 12, 2024, 07:45pm',
      name: 'Will Smith',
      action: 'Update',
      change: '-',
    },
    {
      date_time: 'Jun 12, 2024, 07:45pm',
      name: 'John Smith',
      action: 'Update',
      change: '-',
    },
    {
      date_time: 'Jun 12, 2024, 07:45pm',
      name: 'David',
      action: 'Update',
      change: '-',
    },
    {
      date_time: 'Jun 12, 2024, 07:45pm',
      name: 'Jane Smith',
      action: 'Update',
      change: '-',
    },
    {
      date_time: 'Jun 12, 2024, 07:45pm',
      name: 'Will Smith',
      action: 'Update',
      change: '-',
    },
    {
      date_time: 'Jun 12, 2024, 07:45pm',
      name: 'John Smith',
      action: 'Update',
      change: '-',
    },
    {
      date_time: 'Jun 12, 2024, 07:45pm',
      name: 'David',
      action: 'Update',
      change: '-',
    },
    {
      date_time: 'Jun 12, 2024, 07:45pm',
      name: 'Jane Smith',
      action: 'Update',
      change: '-',
    },
    {
      date_time: 'Jun 12, 2024, 07:45pm',
      name: 'Jane Smith',
      action: 'Update',
      change: '-',
    },
    {
      date_time: 'Jun 12, 2024, 07:45pm',
      name: 'Jane Smith',
      action: 'Update',
      change: '-',
    },
  ];

  const columns: Column<(typeof deliveryList)[0]>[] = [
    {
      field: 'date_time',
      label: 'DATE & TIME',
      width: 120,
      sortable: true,
      autoResize: false,
    },
    {
      field: 'name',
      label: 'NAME',
      width: 120,
      sortable: true,
      autoResize: false,
    },
    {
      field: 'action',
      label: 'ACTION',
      width: 200,
      sortable: true,
      autoResize: false,
    },
    {
      field: 'change',
      label: 'CHANGE',
      width: 200,
      sortable: true,
      autoResize: false,
    },
  ];

  return (
    <React.Fragment>
      <Dialog
        open={isOpen}
        onClose={onClose}
        aria-labelledby='confirmation-dialog-title'
        aria-describedby='confirmation-dialog-description'
        fullScreen
      >
        <DialogTitle id='Confirm-dialog-title'>
          <div
            className='head'
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <h2 style={{ flex: 1 }}>SAP Inbound Outbound Log History</h2>
            <DialogActions>
              <CancelRoundedIcon
                onClick={onClose}
                sx={{
                  width: 32,
                  height: 32,
                  cursor: 'pointer',
                  color: '#3460DC',
                }}
              />
            </DialogActions>
          </div>
        </DialogTitle>
        <DialogContent>
          <div className='custom-table-wrapper'>
            <Table columns={columns} rows={deliveryList} stickyHeader />
          </div>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}