import styled from '@emotion/styled';
import { Tabs, Typography } from '@mui/material';
import { mediaQuery } from 'theme/viewport';
import { Form } from 'views/components/elements';

const FormField = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  rowGap: 4,
  '& label': {
    fontSize: 14,
    [mediaQuery.down('lg')]: {
      fontSize: 12,
    }
  },
  [mediaQuery.down('lg')]: {
    rowGap: 2,
  }
});

const Required = styled('span')({
  color: 'rgb(220 33 21)',
});

const Actions = styled('div')({
  display: 'flex',
  columnGap: 32,
  marginTop: 32,
  [mediaQuery.down('lg')]: {
    columnGap: 16,
    marginTop: 16,
  },
});

const Content = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  rowGap: 12,
});

const Row = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  columnGap: 16,
});

const CreateNewLink = styled('a')({
  color: '#007bff',
  cursor: 'pointer',
  textDecoration: 'none',
  fontSize: '14px',
  marginTop: '4px',
});

const LineWrapper = styled('div')({
  width: '250px',
  whiteSpace: 'pre-wrap',
  overflowWrap: 'break-word',
  display: '-webkit-box',
  WebkitLineClamp: 9,
  WebkitBoxOrient: 'vertical',
  overflow: 'hidden',
});

const CustomTabs = styled(Tabs)({
  '& .MuiTab-root': {
    color: '#1D4ED8 !important',
  },
  '& .MuiTabs-indicator': {
    backgroundColor: '#0F172A !important',
  },
  '& .MuiTab-root.Mui-selected': {
    color: '#0F172A !important',
  },
  '& .MuiTabs-scrollButtons.Mui-disabled': {
    opacity: 0,
  },

  [mediaQuery.down('lg')]: {
    '& .MuiTab-root': {
      fontSize: '10px',
      padding: '5px 10px',
      minWidth: '50px',
    },
    '&': {
      minHeight: '10px',
    },
  },
});

export default CustomTabs;


const PopUpForm = styled(Form)({
  width: '100%',
});

const BodyText = styled(Typography)({
  fontFamily: 'Rubik',
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '18.96px',
  textAlign: 'left',
  marginTop: '16px',
});

const TitleText = styled(Typography)({
  fontFamily: 'Rubik',
  fontSize: '20px',
  fontWeight: 500,
  lineHeight: '23.7px',
  textAlign: 'left',
});

const RubikTextLarger = styled(Typography)({
  fontFamily: 'Rubik',
  fontSize: '32px',
  fontWeight: 500,
  lineHeight: '37.92px',
  textAlign: 'left',
  color: '#070417',
  [mediaQuery.down('lg')]: {
    fontSize: '30px',
    lineHeight: '32px',
  }
});

const RubikTextMedium = styled(Typography)({
  fontFamily: 'Rubik',
  fontSize: '14px',
  fontWeight: 500,
  lineHeight: '16.59px',
  textAlign: 'left',
  color: '#475569',
  [mediaQuery.down('lg')]: {
    fontSize: '11px',
    lineHeight: '14px',
  }
});

const RubikTextRegular = styled(Typography)({
  fontFamily: 'Rubik',
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '16.59px',
  textAlign: 'left',
  color: '#475569',
  [mediaQuery.down('lg')]: {
    fontSize: '11px',
    lineHeight: '14px',
  }
});

export {
  FormField,
  Required,
  Actions,
  Content,
  Row,
  CreateNewLink,
  LineWrapper,
  CustomTabs,
  PopUpForm,
  BodyText,
  TitleText,
  RubikTextLarger,
  RubikTextMedium,
  RubikTextRegular,
};
