import { Form, StepWizardProps } from 'views/components/elements';
import {
  styled,
  Box,
  Typography,
  Button,
  LinearProgress,
  LinearProgressProps,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import React, { useState } from 'react';
import { UploadedIcon } from 'views/components/icon';
import AlertDialog from 'views/components/elements/AlertDialog'; // Import the AlertDialog component
import { createInbound, sapImport } from 'gateways/inbound';
import { handleError } from 'views/shared/utils/error-utils';
import { FileUploadResponse } from 'models/File';
import { useAlertStore } from 'store/alert';
import { CreateInboundResponseDto } from 'models';

const PopUpForm = styled(Form)({
  width: '100%',
  boxSizing: 'border-box',
});

const Content = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  rowGap: 10,
  border: '2px dashed #95ADEC',
  padding: '20px',
  borderRadius: '8px',
  textAlign: 'center',
  backgroundColor: '#f5f6f8c7',
});

interface LoadImportFileProps extends StepWizardProps {
  file: File;
  onSuccess?: (data: CreateInboundResponseDto) => void;
}

const LoadImportFile: React.FC<LoadImportFileProps> = props => {
  const { goToNamedStep, file, onSuccess } = props;
  const navigate = useNavigate();
  const { setAlert } = useAlertStore();
  const [alertOpen, setAlertOpen] = useState(false);

  const handleCancel = () => {
    setAlertOpen(false);
  };

  const handleDiscard = () => {
    setAlertOpen(false);
    navigate('/inbound', { replace: true });
  };

  const [progress, setProgress] = React.useState<number>(0);

  const fileUpload = async (file: File, setProgress) => {
    const apiResponse = await sapImport(file, setProgress);

    const { error, failed, data } =
      handleError<FileUploadResponse>(apiResponse);

    if (failed) {
      return setAlert(error);
    }

    return data.location;
  };

  const inboundCreation = async (url: string) => {
    const apiResponse = await createInbound(url);

    const { error, failed, data } =
      handleError<CreateInboundResponseDto>(apiResponse);

    if (failed) {
      return setAlert(error);
    }

    return data;
  };

  React.useEffect(() => {
    if (file) {
      const timer = setTimeout(async () => {
        const url = await fileUpload(file, setProgress);
        if (!url) {
          return;
        }

        const data = await inboundCreation(url);

        if (!data) {
          return;
        }

        onSuccess(data);

        if (data.newProducts?.count > 0) {
          goToNamedStep('new-products');
        } else {
          goToNamedStep('summary-report');
        }
      }, 300);

      return () => {
        clearTimeout(timer);
      };
    }
  }, []);

  function LinearProgressWithLabel(
    props: LinearProgressProps & { value: number }
  ) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ width: '100%', mr: 1 }}>
          <LinearProgress
            variant='determinate'
            {...props}
            sx={{
              backgroundColor: '#F1F5F9',
              height: 7, // Adjust height for thickness
              borderRadius: 5, // Makes the ends rounded
              '& .MuiLinearProgress-bar': {
                borderRadius: 5, // Rounds the progress indicator itself
                backgroundColor: '#34D399', // Set progress bar color to green
              },
            }}
          />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant='body2' sx={{ color: 'text.secondary' }}>
            {`${Math.round(props.value)}%`}
          </Typography>
        </Box>
      </Box>
    );
  }

  if (!file) {
    return null;
  }

  return (
    <>
      <PopUpForm
        className='user-form form'
        onSubmit={undefined}
        initialValues={{}}
      >
        <Content>
          <UploadedIcon />
          <Typography variant='body1' sx={{ fontWeight: 'bold' }}>
            Importing file...
          </Typography>

          {/* Wrapper for filename and progress bar */}
          <Box
            sx={{
              width: '70%',
              display: 'flex',
              flexDirection: 'column',
              gap: 1,
            }}
          >
            <Typography
              variant='caption'
              sx={{
                color: '#4E545F',
                textAlign: 'left',
              }}
            >
              {file.name}
            </Typography>
            <LinearProgressWithLabel value={progress} color='success' />
          </Box>

          <br />
          <Typography variant='caption' color='#838993'>
            File Type: XLS | Max file size 10MB
          </Typography>
        </Content>

        <Box mt={3} display='flex' justifyContent='right'>
          <Button
            onClick={() => setAlertOpen(true)}
            variant='text'
            size='small'
            color='secondary'
            className='cancel-btn'
            sx={{
              textTransform: 'none',
              color: '#323130',
              border: '1px solid #8A8886',
            }}
          >
            Cancel
          </Button>
        </Box>
      </PopUpForm>
      <AlertDialog
        open={alertOpen}
        title='Discard Changes?'
        message='You have unsaved changes. If you discard now, all your changes will be lost. Are you sure want to proceed?'
        onCancel={handleCancel}
        onDiscard={handleDiscard}
      />
    </>
  );
};

export default LoadImportFile;
