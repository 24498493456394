import { Link, useLocation } from 'react-router-dom';
import { Button, Column, Form, Input, Table } from 'views/components/elements';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Replay as RefreshIcon,
  Search as SearchIcon,
  Cached as ResetIcon,
} from '@mui/icons-material';
import { Action, FlexRow } from 'views/components/styled';
import WarningCard from 'views/components/elements/WarningCard';
import { PenSquareIcon } from 'views/components/icon';
import { Outbound, OutboundStatus, OutboundType } from 'models';
import { useOutboundStore } from 'store';
import { CircularProgress } from '@mui/material';
import { DashBoardList, TextDashBoardList } from '../delivery/dashboard';
import { DashBoardDatePickerField } from 'views/pages/delivery/dashboard/dashboard-date-picker';
import { CustomDateRangeOptions } from 'views/pages/delivery/dashboard/reusables';
import { getFormattedDate } from 'views/shared/utils/string-utils';
import CustomMenu from 'views/components/elements/CustomMenu';
import styled from '@emotion/styled';
import { RubikTextLarger } from '../delivery/StyledComponent';

type MappedOutboundType = { [key in OutboundType]: (keyof Outbound)[] };

const DATE_FORMAT = 'YYYY-MM-DD';

const HIDDEN_FIELDS: MappedOutboundType = {
  b2b: [
    'priority_value',
    'allow_short_ship',
    'packing_instructions',
    'can_be_consolidated',
    'destination_location',
    'gst_invoice_number',
    'despatch_time',
  ],
  b2c: [
    'priority_value',
    'allow_short_ship',
    'packing_instructions',
    'can_be_consolidated',
    'destination_location',
    'gst_invoice_number',
    'despatch_time',
  ],
  rtv: [
    'priority_value',
    'allow_short_ship',
    'packing_instructions',
    'can_be_consolidated',
    'destination_location',
  ],
  transfer: [
    'priority_value',
    'allow_short_ship',
    'packing_instructions',
    'can_be_consolidated',
    'destination_location',
  ],
};

const FIELDS_TO_SHOW: MappedOutboundType = {
  b2b: [
    'outbound_number',
    'order_number',
    'sales_order_number',
    'invoice_number',
    'invoice_date',
    'warehouse_name',
    'customer_name',
    'status',
  ],
  b2c: [
    'outbound_number',
    'order_number',
    'sales_order_number',
    'invoice_number',
    'invoice_date',
    'warehouse_name',
    'customer_name',
    'status',
  ],
  rtv: [
    'outbound_number',
    'supplier_ra_number',
    'despatch_time',
    'warehouse_name',
    'supplier',
    'status',
  ],
  transfer: [
    'outbound_number',
    'order_number',
    'despatch_time',
    'warehouse_name',
    'destination_warehouse',
    'status',
  ],
};

const TableHolderDiv = styled('div')({
  position: 'relative',
});

const AbsolutePositionedDiv = styled('div')({
  position: 'absolute',
  top: '12px',
  right: '12px',
});

type OutboundKeys = keyof Omit<
  Outbound,
  | 'outbound_id'
  | 'type_id'
  | 'source_warehouse_id'
  | 'supplier_id'
  | 'status_id'
  | 'customer_id'
  | 'warehouse_id'
  | 'business_id'
  | 'created_date_time'
  | 'updated_date_time'
  | 'destination_warehouse_id'
>;

const OUTBOUND_COLUMNS: {
  [key in OutboundKeys]: Column<Outbound>;
} = {
  outbound_number: {
    field: 'outbound_number',
    label: 'Outbound Number',
    sortable: true,
    autoResize: false,
    width: '25ch',
  },
  customer_name: {
    field: 'customer',
    label: 'Customer',
    autoResize: false,
    width: '20ch',
  },
  warehouse_name: {
    field: 'warehouse',
    label: 'Warehouse',
    autoResize: false,
    width: '20ch',
  },
  packing_instructions: {
    field: 'packing_instructions',
    label: 'Packing Instructions',
    autoResize: false,
    width: '25ch',
  },
  despatch_time: {
    field: 'despatch_time',
    label: 'Despatch Time',
    autoResize: false,
    width: '25ch',
  },
  destination_location: {
    field: 'destination_location',
    label: 'Destination Location',
    autoResize: false,
    width: '25ch',
  },
  gst_invoice_number: {
    field: 'gst_invoice_number',
    label: 'GST Invoice Number',
    autoResize: false,
    width: '25ch',
  },
  order_number: {
    field: 'order_number',
    label: 'Order Number',
    autoResize: false,
    width: '20ch',
  },
  sales_order_number: {
    field: 'sales_order_number',
    label: 'SO Number',
    autoResize: false,
    width: '20ch',
  },
  invoice_number: {
    field: 'invoice_number',
    label: 'Invoice Number',
    autoResize: false,
    width: '20ch',
  },
  invoice_date: {
    field: 'invoice_date',
    label: 'Invoice Date',
    autoResize: false,
    width: '20ch',
  },
  status: {
    field: 'status',
    label: 'Status',
    autoResize: false,
    width: '20ch',
  },
  comments: {
    field: 'comments',
    label: 'Comments',
    autoResize: false,
    width: '20ch',
  },
  description: {
    field: 'description',
    label: 'Description',
    autoResize: false,
    width: '20ch',
  },
  priority_value: {
    field: 'priority_value',
    label: 'Priority Value',
    autoResize: false,
    width: '25ch',
  },
  allow_short_ship: {
    field: 'allow_short_ship',
    label: 'Allow Short Ship',
    autoResize: false,
    width: '25ch',
  },
  supplier_ra_number: {
    field: 'supplier_ra_number',
    label: 'Supplier RA Number',
    autoResize: false,
    width: '25ch',
  },
  supplier: {
    field: 'supplier',
    label: 'Supplier',
    autoResize: false,
    width: '20ch',
  },
  can_be_consolidated: {
    field: 'can_be_consolidated',
    label: 'Can Be Consolidated',
    autoResize: false,
    width: '25ch',
  },
  destination_warehouse: {
    field: 'destination_warehouse',
    label: 'Destination Warehouse',
    autoResize: false,
    width: '25ch',
  },
};

const outboundStatusFilter = {
  in_progress: ['picked', 'packed', 'started'],
  pending: ['pending', 'released'],
  completed: ['dispatched'],
};

const OutboundList = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const query = queryParams.get('query') || undefined;
  const isResetDisabled = !query;

  const navigate = useNavigate();

  const fields = (queryParams.get('fields')?.split(',') || []).filter(
    field => field.length
  );
  const outboundType: OutboundType =
    (queryParams.get('type') as OutboundType) || 'b2b';
  const status = (queryParams.get('status') || '') as OutboundStatus;
  const selectedStatus = status.toString().replace(/\s/, '_');
  const label = (queryParams.get('label') ||
    'view-all') as CustomDateRangeOptions;
  const fromDate = queryParams.get('fromDate') || undefined;
  const toDate = queryParams.get('toDate') || undefined;
  const { from, to } = getFormattedDate(DATE_FORMAT, {
    label,
    from: fromDate,
    to: toDate,
  });

  const [sortConfig, setSortConfig] = useState({
    field: '',
    order: null,
  });

  const {
    outbounds,
    fetchOutboundsPaginated,
    fetchOutboundTransactionDetail,
    aggregatedData,
    hasNextPage,
    filter,
    fetching,
    error,
    setError,
    resetOutboundData,
  } = useOutboundStore();

  const formRef = useRef(null);

  useEffect(() => {
    if (outbounds.length === 0) {
      fetchOutboundsPaginated({
        query,
        toDate,
        fromDate,
        statuses: outboundStatusFilter[selectedStatus],
        type: outboundType
      });
    }

    return () => resetOutboundData();
  }, []);

  useEffect(() => {
    fetchOutboundsPaginated(
      {
        query,
        toDate,
        fromDate,
        statuses: outboundStatusFilter[selectedStatus],
        type: outboundType
      }
    );
  }, [location]);

  useEffect(() => {
    fetchOutboundTransactionDetail(from, to);
  }, [from, to]);

  const handleSearch = (values: typeof filter) => {
    queryParams.set('query', values.query ?? '');
    queryParams.set('page', '1');
    navigate(`/outbound?${queryParams.toString()}`);
  };

  const handleOutboundTypeChange = (key: string) => {
    queryParams.set('type', key);
    queryParams.delete('fields');
    navigate(`/outbound?${queryParams.toString()}`);
  };

  const handleRefresh = () => {
    navigate('/outbound');
  };

  const handleStatusChange = (key: string) => {
    queryParams.set('status', key);
    navigate(`/outbound?${queryParams.toString()}`);
  };

  const handleDateFilterChange = (
    label: CustomDateRangeOptions,
    fromDate: Date,
    toDate: Date
  ) => {
    const { from, to } = getFormattedDate(DATE_FORMAT, {
      label,
      from: fromDate,
      to: toDate,
    });
    queryParams.set('fromDate', from);
    queryParams.set('toDate', to);
    queryParams.set('label', label);
    navigate(`/outbound?${queryParams.toString()}`);
  };

  const sortedOutbounds = [...outbounds].sort((a, b) => {
    if (a[sortConfig.field] < b[sortConfig.field])
      return sortConfig.order === 'asc' ? -1 : 1;
    if (a[sortConfig.field] > b[sortConfig.field])
      return sortConfig.order === 'asc' ? 1 : -1;
    return 0;
  });

  const handleNextPage = () => {
    const newFilter = Object.assign({}, filter, {
      page: filter.page + 1,
    });

    fetchOutboundsPaginated(newFilter);
  };

  const handleReset = () => {
    if (formRef.current) {
      formRef.current.resetForm({ values: { query: '' } });
    }

    queryParams.delete('query');
    navigate(`/outbound?${queryParams.toString()}`);
  };

  const noRecordFoundMsg = ((status: OutboundType) => {
    switch (status) {
    case 'b2b':
    case 'b2c':
      return 'No order request found.';
    case 'transfer':
      return 'No transfer request found.';
    case 'rtv':
      return 'No return to vendor request found.';
    }
  })(outboundType);

  // Handle Table columns and hidden menu based on the outbound type
  const menuOptions = HIDDEN_FIELDS[outboundType].map((label, index) => ({
    id: `${index + 1}`,
    label: label.replace(/_/g, ' '),
  }));

  const defaultColumnsToShow: Column<Outbound>[] = FIELDS_TO_SHOW[
    outboundType
  ].map(field => OUTBOUND_COLUMNS[field]);
  const extraColumnsToShow = [];

  if (fields.length) {
    const fieldSet = new Set(fields);
    const fieldsToShow = menuOptions
      .filter(({ id }) => fieldSet.has(id))
      .map(({ label }) => OUTBOUND_COLUMNS[label.replace(/\s/g, '_')]);
    extraColumnsToShow.push(...fieldsToShow);
  }

  const tableColumns: Column<Outbound>[] = [
    ...defaultColumnsToShow,
    ...extraColumnsToShow,
  ];

  const handleMenuChange = (options: string[]) => {
    const optionsString = options.toString();
    const fieldsString = fields.toString();

    if (optionsString === fieldsString) {
      return;
    }

    if (!optionsString) {
      queryParams.delete('fields');
    } else {
      queryParams.set('fields', optionsString);
    }

    navigate(`/outbound?${queryParams.toString()}`);
  };

  return (
    <div className='custom-table-wrapper'>
      <WarningCard
        open={Boolean(error)}
        onClose={() => setError(null)}
        message={error}
        severity='error'
        durationInSeconds={10}
      />
      <div className='head' style={{ marginBottom: '3rem' }}>
        <FlexRow style={{ gap: '1rem' }}>
          <RubikTextLarger>Outbound</RubikTextLarger>
          <DashBoardList
            data={aggregatedData as any}
            onChange={handleOutboundTypeChange}
            selectedField={outboundType}
          >
            <DashBoardDatePickerField
              label={label}
              from={from as unknown as Date}
              to={to as unknown as Date}
              onChange={handleDateFilterChange}
            />
          </DashBoardList>
          <div style={{ marginLeft: 'auto'}}>
            <TextDashBoardList
              data={['pending', 'in progress', 'completed']}
              onChange={handleStatusChange}
              selectedField={status}
            />
          </div>
        </FlexRow>
      </div>

      <Action>
        <Form
          innerRef={formRef}
          initialValues={{ query: query ?? '' }}
          onSubmit={handleSearch}
          validateOnChange={false}
        >
          <Input
            name='query'
            placeholder='Search Outbounds'
            startAdornment={<SearchIcon />}
          />
          <Button type='submit'>
            <SearchIcon />
          </Button>
          <Button
            variant='text'
            startIcon={
              <span id='package-icon'>
                <RefreshIcon />
              </span>
            }
            onClick={handleReset}
            disabled={isResetDisabled}
          >
            Reset
          </Button>
          <Button
            variant='text'
            startIcon={
              <span id='package-icon'>
                <ResetIcon />
              </span>
            }
            onClick={handleRefresh}
          >
            Refresh
          </Button>
        </Form>

        <FlexRow>
          <Button
            component={Link}
            variant='contained'
            style={{ height: '40px' }}
            to='/outbound?import=true'
          >
            SAP Import
          </Button>
          <Button
            component={Link}
            variant='contained'
            style={{ height: '40px' }}
            to='/outbound?import=true'
          >{`Add ${outboundType === 'transfer' ? 'Transfer': outboundType.toUpperCase()}`}</Button>
        </FlexRow>
      </Action>

      {fetching && (
        <div className='spinner'>
          <CircularProgress />
        </div>
      )}

      <TableHolderDiv>
        <AbsolutePositionedDiv>
          <CustomMenu
            options={menuOptions}
            onSelectionChange={handleMenuChange}
            defaultSelectedOptions={fields}
          >
            <PenSquareIcon />
          </CustomMenu>
        </AbsolutePositionedDiv>

        <div style={{ width: 'calc(100% - 50px)', overflow: 'auto' }}>
          <Table
            rows={sortedOutbounds}
            columns={tableColumns}
            stickyHeader
            infiniteScroll
            hasMoreRows={hasNextPage}
            sortingField={sortConfig.field}
            sortingOrder={sortConfig.order}
            onSortChange={(field, order) => setSortConfig({ field, order })}
            onNextPage={handleNextPage}
          />

          {!fetching && outbounds.length === 0 && (
            <div className='margin-top-24px text-center'>
              {noRecordFoundMsg}
            </div>
          )}
        </div>
      </TableHolderDiv>
    </div>
  );
};

export { OutboundList };
