import { ErrorResponse } from 'models';
import { sendRequest, getRequest, postRequest, putRequest } from './gateway';
import { SupplierListResponse } from 'models/Supplier';

function createSupplier({ code, name, address, phone, gst_number }: SupplierListResponse) {
  const postPromise = postRequest<ErrorResponse>('/v1/supplier', {
    code,
    name,
    address,
    phone,
    gst_number,
  });
  return sendRequest(postPromise);
}
  
function updateSupplier({ supplier_id, code, name, address, phone, gst_number }: SupplierListResponse) {
  const putPromise = putRequest<ErrorResponse>(`/v1/supplier/${supplier_id}`, {
    code,
    name,
    address,
    phone,
    gst_number,
    supplier_id
  });
  return sendRequest(putPromise);
}

function getSupplierById({ supplier_id }: { supplier_id: string }): Promise<any> {
  const putPromise = getRequest<ErrorResponse>(`/v1/supplier/${supplier_id}}`);
  return sendRequest(putPromise);
}

export {
  createSupplier,
  updateSupplier,
  getSupplierById
};
